// import React from "react";
// import { userHistory } from "react-router-dom";

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // history.push('/')
        localStorage.removeItem('auth_token')
        localStorage.removeItem('email')
        window.location.replace("/login");
        // window.location.reload(true);
      }
      let error;
      if (data.error) {
       
        error = (data.error ? data.error : data.message) || response.statusText;
      } else {
        error =
          (data && data.errors ? data.errors : data.message) ||
          response.statusText;
      }
      // const error = (data && (data.error ? data.error : data.errors ? data.errors.message : 'Unknown error')) || response.status
      // console.log(error)
      return Promise.reject(error);
    }

    return data;
  });
}
