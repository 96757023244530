// import { blue } from "@material-ui/core/colors";
// import { useEffect, useState, useRef } from "react";
// import { useHistory } from "react-router-dom";
// import { baseUrl } from "../../helpers/constants";
// import logoImage from "../../images/signlogo.png";

// const PDFViewerHeader = (props) => {
//   const [show, setShow] = useState(false);
//   const [showLogout, setShowLogout] = useState(false);
//   const ref = useRef();
//   const history = useHistory();
//   const [button, setButton] = useState(false);
//   // console.log(props.isArchive,"hooooooo");
//   // console.log(props.isVoid,"hooooooo");
//   const goToHomePage = () => {
//     history.push("/dashboard");
//   };

//   useEffect(() => {
//     const onBodyClick = (event) => {
//       if (ref.current && ref.current.contains(event.target)) {
//         return;
//       }

//       setShow(false);
//     };
//     document.body.addEventListener("click", onBodyClick);
//     showLogoutButton();
//     return () => {
//       document.body.removeEventListener("click", onBodyClick);
//     };
//   }, []);

//   const showLogoutButton = () => {
//     let authToken = window.localStorage.getItem("auth_token");
//     if (authToken) {
//       setShowLogout(true);
//     }
//   };

//   return (
//     <div ref={ref} className="mt-12">
//       <div
//         className="antialiased bg-gray-100 dark-mode:bg-gray-900"
//         style={{
//           position: "fixed",
//           width: "100%",
//           // zIndex: props.setShowInfo == false ? 100 : 0,
//           zIndex: "100",
//           top: "0",
//           boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
//         }}
//       >
//         <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
//           <div
//             x-data={() => setButton(false)}
//             className="flex md:justify-between md:flex-row md:px-6 lg:px-8"
//           >
//             <div className="p-4 flex flex-row items-center justify-between col-lg-7 col-md-11 col-sm-11 col-xs-12  ">
//               {/* <div className="col-lg-8 col-md-6 col-sm-2 col-xs-2"> */}
//               <div style={{ cursor: "pointer" }} onClick={goToHomePage}>
//                 <img
//                   className="mx-auto h-12"
//                   style={{ height: 30, paddingRight: 10 }}
//                   src={logoImage}
//                   alt="Workflow"
//                 />
//               </div>
//               <a
//                 href={`${baseUrl}/login`}
//                 className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
//                 style={{ color: blue }}
//               >
//                 {/* SignPe */}
//               </a>
//               {/* </div> */}
//               <div
//                 className=" xl-1 text-md text-gray-900 font-weight-bold col-lg-4 col-md-6 col-sm-10 col-xs-10 "
//                 title={props?.text}
//                 style={{
//                   // maxWidth: "px",
//                   whiteSpace: "nowrap",
//                   overflow: "hidden",
//                   textOverflow: "ellipsis",
//                   textAlign: "end",
//                   // display: "flex",
//                   // justifyContent: "end",
//                 }}
//               >
//                 {/* <div className="col-4 text-md text-gray-900 font-weight-bold"> */}
//                 {props.text}
//               </div>
//               {/* <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => { setButton(!button) }} >
//                 <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
//                   {button && (<path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h 12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd" />)}
//                   {!button && (<path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />)}
//                 </svg>
//               </button> */}
//             </div>

//             {/*
//         <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
//           <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
//             <div className="col-4 flex-row items-center justify-between p-4">
//               <a
//                 href={`${baseUrl}/login`}
//                 className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
//               >
//                 Sign App
//               </a>
//             </div>
//             <div className="col-4 text-md text-gray-900 text-center font-weight-bold">
//               {props.text}
//             </div> */}

//             <nav
//               className={` col-lg-5 col-md-1 col-sm-1 col-xs-12 flex-col flex-grow pb-4 md:pb-0  md:flex md:justify-end md:flex-row  ${
//                 !button ? "flex" : "hidden"
//               } `}
//               style={{
//                 marginBottom: "-18px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "end",
//               }}
//             >
//               {!props.isVoid &&
//                 !props.isArchive &&
//                 props.status !== "SENT" &&
//                 props.isCurrentUserSigned && (
//                   <div
//                     className="pr-4 py-3"
//                     style={{
//                       paddingLeft: "32px",
//                       position: "relative",
//                       left: 30,
//                     }}
//                   >
//                     <a
//                       onClick={props.onSubmit}
//                       className="text-semibold font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-white"
//                       href="#"
//                       style={{ fontSize: 14 }}
//                     >
//                       SUBMIT
//                     </a>
//                   </div>
//                 )}

//               <div
//                 className="pr-4 py-3 d-flex justify-content-end align-items-center "
//                 style={{
//                   paddingLeft: 10,
//                   position: "relative",
//                   left: 10,
//                   width: "100%",
//                 }}
//               >
//                 <a
//                   onClick={() => {
//                     setShow(false);
//                     props.showDocumentHistory();
//                   }}
//                   className="text-semibold font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-white"
//                   href="#"
//                   style={{ fontSize: 14 }}
//                 >
//                   AUDIT TRAIL
//                 </a>
//               </div>
//               {/* <div x-data={() => setShow(false)}>
//                 <button

//                   onClick={() => {
//                     setShow(false);
//                     props.showDocumentHistory();
//                   }}
//                   className="w-full rounded-lg bg-transparent p-2"
//                   href="#"
//                 >
//                   <div className="flex flex-row space-x-2">
//                     <p className="font-semibold">Audit Trail</p>
//                   </div>

//                 </button>

//                 {show ? (

//                   <div
//                     x-show="open"
//                     className="absolute w-full ml-4 mt-1"
//                     style={{
//                       zIndex: -20,
//                     }}
//                   >
//                     <div
//                       className="px-2 pt-2 pb-2 bg-white shadow-lg dark-mode:bg-gray-700"
//                       style={{
//                         borderRadius: "10px",
//                         borderTopRightRadius: "10px",
//                         borderTopLeftRadius: 0,
//                         width: "150px"
//                       }}
//                     >
//                       <div className="grid gap-4 justify-content-center">
//                         <a
//                           onClick={() => {
//                             setShow(false);
//                             props.showDocumentHistory();
//                           }}
//                           className="w-full rounded-lg bg-transparent p-2"
//                           href="#"
//                         >
//                           <div className="flex flex-row space-x-2">
//                             <p className="font-semibold">Audit Trail</p>
//                           </div>
//                         </a>
//                         {showLogout && <a
//                           onClick={() => {
//                             window.localStorage.clear();
//                             history.push("/login");
//                           }}
//                           className="items-start w-full rounded-lg bg-transparent p-2"
//                           href="#"
//                         >
//                           <div className="flex flex-row space-x-2">
//                             <p className="font-semibold">Logout</p>
//                           </div>
//                         </a>}

//                       </div>
//                     </div>
//                   </div>
//                 ) : null}
//               </div> */}
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// // Header.defaultProps = {
// //   text: "",
// //   showLogout: false,
// // };

// export default PDFViewerHeader;

// // <div style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px" }}>
// // <div className="antialiased bg-gray-100 dark-mode:bg-gray-900">

// //   <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
// //     <div x-data={() => setButton(false)} className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
// //       <div className="p-4 flex flex-row items-center justify-between col-lg-4 col-md-4 col-sm-12 col-xs-12  ">
// //         <a
// //           style={{ cursor: "pointer", textDecoration: "none" }}
// //           href={`${baseUrl}/login`}
// //           className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none "
// //         >
// //           Sign App
// //       </a>
// //         <div className=" xl-1 text-md text-gray-900 font-weight-bold  ">
// //           {/* <div className="col-4 text-md text-gray-900 font-weight-bold"> */}
// //           {props.text}
// //         </div>
// //         <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => { setButton(!button) }} >
// //           <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
// //             {button && (<path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h 12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd" />)}
// //             {!button && (<path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />)}
// //           </svg>
// //         </button>
// //       </div>

// //       <nav className={`flex-col flex-grow pb-4 md:pb-0  md:flex md:justify-end md:flex-row  ${!button ? "flex" : "hidden"} `} style={{ marginBottom: "-18px" }}>
// //         <a
// //           style={{ cursor: "pointer", textDecoration: "none" }}
// //           className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
// //           href={"/contact"}
// //         >
// //           Contact
// //       </a>
// //         {props.showLogout === true ? (
// //           <a
// //             style={{ cursor: "pointer", textDecoration: "none" }}
// //             className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
// //             onClick={() => {
// //               window.localStorage.clear();
// //               history.push("/login");
// //             }}
// //             onClick={() => setShow(true)}
// //           >
// //             Logout
// //           </a>
// //         ) : null}

// //         {/* <div className="relative" x-data={() => setDropdown(false)}>
// //           <button onClick={() => { setDropdown(!dropdown) }} className="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
// //             <span>Dropdown</span>
// //             <svg fill="currentColor" viewBox="0 0 20 20" className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 ${dropdown ? 'rotate-180' : 'rotate-0'}`}>      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
// //           </button>
// //           <div x-show="open" className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
// //             <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
// //               <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #1</a>
// //               <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #2</a>
// //               <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #3</a>
// //             </div>
// //           </div>
// //         </div> */}
// //       </nav>

// //       <div>
// //         <Modal
// //           style={customStyles}
// //           isOpen={show}
// //           onRequestClose={() => {
// //             setShow(false);
// //           }}
// //         >
// //           <div style={{ alignContent: "left", fontWeight: "500", marginBottom: "5px", fontSize: "23px" }}>Logout Alert</div>
// //         Are you sure you want to logout?
// //         <div style={{ marginLeft: "225px", paddingTop: "55px" }}>
// //             <span
// //               style={{
// //                 cursor: "pointer",
// //                 textAlign: "right",
// //                 border: "0px",
// //                 width: "80px",
// //                 backgroundColor: "transparent",
// //                 color: "#2b2b2b"
// //               }}
// //               onClick={() => {
// //                 setShow(false)
// //               }}>Cancel</span>
// //             <span
// //               style={{
// //                 cursor: "pointer",
// //                 marginLeft: "30px",
// //                 textAlign: "right",
// //                 border: "0px",
// //                 width: "80px",
// //                 backgroundColor: "transparent",
// //                 color: "#007bff"
// //               }}
// //               onClick={() => {
// //                 window.localStorage.clear();
// //                 history.push("/login");
// //               }}>Logout</span>

// //           </div>
// //         </Modal>
// //       </div>

// //     </div>
// //   </div>
// // </div>
// // </div>

// // <div ref={ref} className="mt-12">
// // <div
// //   className="antialiased bg-gray-100 dark-mode:bg-gray-900"

// //   style={{
// //     position: "fixed",
// //     width: "100%",
// //     // zIndex: props.setShowInfo == false ? 100 : 0,
// //     zIndex: "100",
// //     top: "0",
// //     boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
// //   }}
// // >

// //   <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
// //     <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
// //       <div className="col-4 flex-row items-center justify-between p-4">
// //         <a
// //           href={`${baseUrl}/login`}
// //           className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
// //         >
// //           Sign App
// //         </a>
// //       </div>
// //       <div className="col-4 text-md text-gray-900 text-center font-weight-bold">
// //         {props.text}
// //       </div>
// //       <div className="col ">
// //         <div className="row">
// //           <div className="col text-right">
// //             {props.isVoid === false && (<div className="pl-4 py-3 mt-2">
// //               <a
// //                 onClick={props.onSubmit}
// //                 className="text-semibold font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-white"
// //                 href="#"
// //               >
// //                 SUBMIT
// //               </a>
// //             </div>)}
// //           </div>
// //           <div className="col">
// //             <div>
// //               <button
// //                 onClick={() => {
// //                   setShow(!show)

// //                 }
// //                 }

// //                 className={`text-gray-900 text-semibold font-semibold
// //                  items-center text-left w-full pl-12 ml-8 py-3 mt-2 rounded-lg focus:outline-none ${show ? "focus:bg-gray-200" : ""
// //                   }`}
// //               >
// //                 <span>MORE</span>
// //                 <svg
// //                   fill="currentColor"
// //                   viewBox="0 0 15 15"
// //                   class={`inline w-4 h-4 ml-1 transition-transform duration-200 transform md:-mt-2`}
// //                 >
// //                   <path
// //                     fill-rule="evenodd"
// //                     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
// //                     clip-rule="evenodd"
// //                   />
// //                 </svg>
// //               </button>
// //               {show ? (

// //                 <div
// //                   x-show="open"
// //                   className="absolute w-full ml-4 mt-1"
// //                   style={{
// //                     zIndex: -20,
// //                   }}
// //                 >
// //                   <div
// //                     className="px-2 pt-2 pb-2 bg-white shadow-lg dark-mode:bg-gray-700"
// //                     // class name change className="px-2 pt-2 pb-4 bg-white shadow-lg dark-mode:bg-gray-700"
// //                     style={{
// //                       borderRadius: "10px",
// //                       borderTopRightRadius: "10px",
// //                       borderTopLeftRadius: 0,
// //                     }}
// //                   >
// //                     <div className="grid gap-4 justify-content-center">
// //                       <a
// //                         onClick={() => {
// //                           setShow(false);
// //                           props.showDocumentHistory();
// //                         }}
// //                         className="w-full rounded-lg bg-transparent p-2"
// //                         href="#"
// //                       >
// //                         <div className="flex flex-row space-x-2">
// //                           <p className="font-semibold">History</p>
// //                         </div>
// //                       </a>
// //                       {showLogout && <a
// //                         onClick={() => {
// //                           window.localStorage.clear();
// //                           history.push("/login");
// //                         }}
// //                         className="items-start w-full rounded-lg bg-transparent p-2"
// //                         href="#"
// //                       >
// //                         <div className="flex flex-row space-x-2">
// //                           <p className="font-semibold">Logout</p>
// //                         </div>
// //                       </a>}
// //                       {/* <a
// //                       className="items-start w-full rounded-lg bg-transparent p-2 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
// //                       href="#"
// //                     >
// //                       <div className="flex flex-row space-x-2">
// //                         <p>image</p>
// //                         <p className="font-semibold">Appearance</p>
// //                       </div>
// //                     </a> */}
// //                     </div>
// //                   </div>
// //                 </div>
// //               ) : null}
// //             </div>
// //           </div>
// //           {/* {props.showLogout === true ? (
// //               <button
// //                 className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
// //                 onClick={() => {
// //                   window.localStorage.clear();
// //                   history.push("/login");
// //                 }}
// //               >
// //                 Logout
// //               </button>
// //             ) : null}
// //           </div> */}
// //         </div>
// //       </div>
// //     </div>
// //   </div>
// // </div>
// // </div>
import { blue } from "@material-ui/core/colors";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../helpers/constants";
import logoImage from "../../images/updated_signlogo.png";

const PDFViewerHeader = (props) => {
  const [show, setShow] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const ref = useRef();
  const history = useHistory();
  const [button, setButton] = useState(false);
  // console.log(props.isArchive,"hooooooo");
  // console.log(props.isVoid,"hooooooo");
  const goToHomePage = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }

      setShow(false);
    };
    document.body.addEventListener("click", onBodyClick);
    showLogoutButton();
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  const showLogoutButton = () => {
    let authToken = window.localStorage.getItem("auth_token");
    if (authToken) {
      setShowLogout(true);
    }
  };

  return (
    <div ref={ref} className="mt-12 ">
      <div
        className="antialiased bg-gray-100 dark-mode:bg-gray-900"
        style={{
          position: "fixed",
          width: "100%",
          // zIndex: props.setShowInfo == false ? 100 : 0,
          zIndex: "100",
          top: "0",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
        }}
      >
        <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
          <div
            x-data={() => setButton(false)}
            className=" md:justify-between md:flex-row md:px-6 lg:px-8"
          >
            <div className=" d-flex justify-content-between align-items-center pdf-viewer-header ">
              <div className="  d-flex justify-content-start ">
                <div style={{ cursor: "pointer" }} onClick={goToHomePage}>
                  <img
                    className="mx-auto h-12"
                    style={{ height: 30, paddingRight: 10 }}
                    src={logoImage}
                    alt="Workflow"
                  />
                </div>
              </div>
              <div className="">
                <div
                  className=" xl-1 text-md text-gray-900 font-weight-bold "
                  title={props?.text}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: window.innerWidth > 1800 ? "end" : "center",
                    // display: "flex",
                    // justifyContent: "end",
                    maxWidth: "200px",
                    minWidth: "200px",
                    width: "100%",
                  }}
                >
                  {/* <div className="col-4 text-md text-gray-900 font-weight-bold"> */}
                  {props.text}
                </div>
              </div>
              <div className="pl-2">
                <nav
                  className={` flex flex-grow   md:pb-0  md:flex md:justify-end md:flex-row  ${
                    !button ? "flex" : "hidden"
                  } `}
                  style={{
                    padding: "20px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  {!props.isVoid &&
                    !props.isArchive &&
                    props.status !== "SENT" &&
                    props.isCurrentUserSigned && (
                      <div
                        className="pr-4   submit-link "
                        style={{
                          position: "relative",
                          left: 30,
                        }}
                      >
                        <a
                          onClick={props?.onSubmit}
                          className="text-semibold font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-white"
                          href="#"
                          style={{ fontSize: 14 }}
                        >
                          SUBMIT
                        </a>
                      </div>
                    )}

                  <div
                    className="pr-3  d-flex justify-content-end align-items-center audit-trail-link"
                    style={{
                      paddingLeft: 10,
                      position: "relative",
                      left: 10,
                      // width: "100%",
                    }}
                  >
                    <a
                      onClick={() => {
                        setShow(false);
                        props.showDocumentHistory();
                      }}
                      className="text-semibold font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-white"
                      href="#"
                      style={{ fontSize: 14 }}
                    >
                      AUDIT TRAIL
                    </a>
                  </div>
                  {/* <div x-data={() => setShow(false)}>
                <button

                  onClick={() => {
                    setShow(false);
                    props.showDocumentHistory();
                  }}
                  className="w-full rounded-lg bg-transparent p-2"
                  href="#"
                >
                  <div className="flex flex-row space-x-2">
                    <p className="font-semibold">Audit Trail</p>
                  </div>

                </button>
                
                {show ? (

                  <div
                    x-show="open"
                    className="absolute w-full ml-4 mt-1"
                    style={{
                      zIndex: -20,
                    }}
                  >
                    <div
                      className="px-2 pt-2 pb-2 bg-white shadow-lg dark-mode:bg-gray-700"
                      style={{
                        borderRadius: "10px",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: 0,
                        width: "150px"
                      }}
                    >
                      <div className="grid gap-4 justify-content-center">
                        <a
                          onClick={() => {
                            setShow(false);
                            props.showDocumentHistory();
                          }}
                          className="w-full rounded-lg bg-transparent p-2"
                          href="#"
                        >
                          <div className="flex flex-row space-x-2">
                            <p className="font-semibold">Audit Trail</p>
                          </div>
                        </a>
                        {showLogout && <a
                          onClick={() => {
                            window.localStorage.clear();
                            history.push("/login");
                          }}
                          className="items-start w-full rounded-lg bg-transparent p-2"
                          href="#"
                        >
                          <div className="flex flex-row space-x-2">
                            <p className="font-semibold">Logout</p>
                          </div>
                        </a>}
                      
                      </div>
                    </div>
                  </div>
                ) : null}
              </div> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Header.defaultProps = {
//   text: "",
//   showLogout: false,
// };

export default PDFViewerHeader;
// <div style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px" }}>
// <div className="antialiased bg-gray-100 dark-mode:bg-gray-900">

//   <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
//     <div x-data={() => setButton(false)} className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
//       <div className="p-4 flex flex-row items-center justify-between col-lg-4 col-md-4 col-sm-12 col-xs-12  ">
//         <a
//           style={{ cursor: "pointer", textDecoration: "none" }}
//           href={`${baseUrl}/login`}
//           className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none "
//         >
//           Sign App
//       </a>
//         <div className=" xl-1 text-md text-gray-900 font-weight-bold  ">
//           {/* <div className="col-4 text-md text-gray-900 font-weight-bold"> */}
//           {props.text}
//         </div>
//         <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => { setButton(!button) }} >
//           <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
//             {button && (<path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h 12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd" />)}
//             {!button && (<path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />)}
//           </svg>
//         </button>
//       </div>

//       <nav className={`flex-col flex-grow pb-4 md:pb-0  md:flex md:justify-end md:flex-row  ${!button ? "flex" : "hidden"} `} style={{ marginBottom: "-18px" }}>
//         <a
//           style={{ cursor: "pointer", textDecoration: "none" }}
//           className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
//           href={"/contact"}
//         >
//           Contact
//       </a>
//         {props.showLogout === true ? (
//           <a
//             style={{ cursor: "pointer", textDecoration: "none" }}
//             className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
//             onClick={() => {
//               window.localStorage.clear();
//               history.push("/login");
//             }}
//             onClick={() => setShow(true)}
//           >
//             Logout
//           </a>
//         ) : null}

//         {/* <div className="relative" x-data={() => setDropdown(false)}>
//           <button onClick={() => { setDropdown(!dropdown) }} className="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
//             <span>Dropdown</span>
//             <svg fill="currentColor" viewBox="0 0 20 20" className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 ${dropdown ? 'rotate-180' : 'rotate-0'}`}>      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
//           </button>
//           <div x-show="open" className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
//             <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
//               <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #1</a>
//               <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #2</a>
//               <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #3</a>
//             </div>
//           </div>
//         </div> */}
//       </nav>

//       <div>
//         <Modal
//           style={customStyles}
//           isOpen={show}
//           onRequestClose={() => {
//             setShow(false);
//           }}
//         >
//           <div style={{ alignContent: "left", fontWeight: "500", marginBottom: "5px", fontSize: "23px" }}>Logout Alert</div>
//         Are you sure you want to logout?
//         <div style={{ marginLeft: "225px", paddingTop: "55px" }}>
//             <span
//               style={{
//                 cursor: "pointer",
//                 textAlign: "right",
//                 border: "0px",
//                 width: "80px",
//                 backgroundColor: "transparent",
//                 color: "#2b2b2b"
//               }}
//               onClick={() => {
//                 setShow(false)
//               }}>Cancel</span>
//             <span
//               style={{
//                 cursor: "pointer",
//                 marginLeft: "30px",
//                 textAlign: "right",
//                 border: "0px",
//                 width: "80px",
//                 backgroundColor: "transparent",
//                 color: "#007bff"
//               }}
//               onClick={() => {
//                 window.localStorage.clear();
//                 history.push("/login");
//               }}>Logout</span>

//           </div>
//         </Modal>
//       </div>

//     </div>
//   </div>
// </div>
// </div>

// <div ref={ref} className="mt-12">
// <div
//   className="antialiased bg-gray-100 dark-mode:bg-gray-900"

//   style={{
//     position: "fixed",
//     width: "100%",
//     // zIndex: props.setShowInfo == false ? 100 : 0,
//     zIndex: "100",
//     top: "0",
//     boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px",
//   }}
// >

//   <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
//     <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
//       <div className="col-4 flex-row items-center justify-between p-4">
//         <a
//           href={`${baseUrl}/login`}
//           className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
//         >
//           Sign App
//         </a>
//       </div>
//       <div className="col-4 text-md text-gray-900 text-center font-weight-bold">
//         {props.text}
//       </div>
//       <div className="col ">
//         <div className="row">
//           <div className="col text-right">
//             {props.isVoid === false && (<div className="pl-4 py-3 mt-2">
//               <a
//                 onClick={props.onSubmit}
//                 className="text-semibold font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:outline-none focus:bg-white"
//                 href="#"
//               >
//                 SUBMIT
//               </a>
//             </div>)}
//           </div>
//           <div className="col">
//             <div>
//               <button
//                 onClick={() => {
//                   setShow(!show)

//                 }
//                 }

//                 className={`text-gray-900 text-semibold font-semibold
//                  items-center text-left w-full pl-12 ml-8 py-3 mt-2 rounded-lg focus:outline-none ${show ? "focus:bg-gray-200" : ""
//                   }`}
//               >
//                 <span>MORE</span>
//                 <svg
//                   fill="currentColor"
//                   viewBox="0 0 15 15"
//                   class={`inline w-4 h-4 ml-1 transition-transform duration-200 transform md:-mt-2`}
//                 >
//                   <path
//                     fill-rule="evenodd"
//                     d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                     clip-rule="evenodd"
//                   />
//                 </svg>
//               </button>
//               {show ? (

//                 <div
//                   x-show="open"
//                   className="absolute w-full ml-4 mt-1"
//                   style={{
//                     zIndex: -20,
//                   }}
//                 >
//                   <div
//                     className="px-2 pt-2 pb-2 bg-white shadow-lg dark-mode:bg-gray-700"
//                     // class name change className="px-2 pt-2 pb-4 bg-white shadow-lg dark-mode:bg-gray-700"
//                     style={{
//                       borderRadius: "10px",
//                       borderTopRightRadius: "10px",
//                       borderTopLeftRadius: 0,
//                     }}
//                   >
//                     <div className="grid gap-4 justify-content-center">
//                       <a
//                         onClick={() => {
//                           setShow(false);
//                           props.showDocumentHistory();
//                         }}
//                         className="w-full rounded-lg bg-transparent p-2"
//                         href="#"
//                       >
//                         <div className="flex flex-row space-x-2">
//                           <p className="font-semibold">History</p>
//                         </div>
//                       </a>
//                       {showLogout && <a
//                         onClick={() => {
//                           window.localStorage.clear();
//                           history.push("/login");
//                         }}
//                         className="items-start w-full rounded-lg bg-transparent p-2"
//                         href="#"
//                       >
//                         <div className="flex flex-row space-x-2">
//                           <p className="font-semibold">Logout</p>
//                         </div>
//                       </a>}
//                       {/* <a
//                       className="items-start w-full rounded-lg bg-transparent p-2 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
//                       href="#"
//                     >
//                       <div className="flex flex-row space-x-2">
//                         <p>image</p>
//                         <p className="font-semibold">Appearance</p>
//                       </div>
//                     </a> */}
//                     </div>
//                   </div>
//                 </div>
//               ) : null}
//             </div>
//           </div>
//           {/* {props.showLogout === true ? (
//               <button
//                 className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
//                 onClick={() => {
//                   window.localStorage.clear();
//                   history.push("/login");
//                 }}
//               >
//                 Logout
//               </button>
//             ) : null}
//           </div> */}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
