import { handleResponse } from "../helpers/handleResponse";
import { baseUrl, endpoint } from "../helpers/constants";
import axios from "axios";

const fetchDocumentMeta = async (link) => {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      origin: baseUrl,
    },
  };

  return fetch(`${endpoint}/users/document/sign/${link}`, requestOptions)
    .then(handleResponse)
    .then((document) => {
      return document;
    });
};

const fetchPdfData = async (document_id) => {
  const requestOptions = {
    method: "GET",
    // headers: {
    //   Accept: "application/json",
    //   origin: baseUrl,
    // },
  };

  return fetch(
    `${endpoint}/transaction/audit-trail/${document_id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((document) => {
      return document;
    });
};

const sentMail = async (email, file, filename, senderEmail, creator) => {
  try {
    const formData = new FormData();
    formData.append("to_email", email);
    formData.append("file", file);
    formData.append("senderEmail", senderEmail);
    formData.append("senderName", creator);
    formData.append("filename", filename ? filename : "sigpe-attachment.pdf");
    let { data } = await axios.post(
      `${endpoint}/users/document/send-email/`,
      formData
    );
    return data;
  } catch (err) {

    return err;
  }
};

// annotations, document_id, email, renderingWidth, renderingHeigh
const updateDocument = async (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  return fetch(`${endpoint}/users/document/update-sign`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

const fetchSignatures = async (id) => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${endpoint}/users/get-signatures/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

const changePassword = async (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  return fetch(`${endpoint}/users/change-password`, requestOptions).then(
    handleResponse
  );
};

// Returns the time stack
const listDocument = async (id) => {
  return fetch(`${endpoint}/users/list-documents-status/${id}`, {
    method: "GET",
  }).then(handleResponse);
};

const listDocumentsForDashboard = async () => {
  const token = window.localStorage.getItem("auth_token");
  return fetch(`${endpoint}/users/document`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

const listDocumentInformation = async (documentId) => {
  return fetch(`${endpoint}/users/document/info/${documentId}`, {
    method: "GET",
  }).then(handleResponse);
};

const deleteDocument = async (documentId) => {
  const token = await window.localStorage.getItem("auth_token");

  return fetch(`${endpoint}/transaction/delete-transaction/${documentId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

const fetchAccountVerification = async (token) => {
  const requestOptions = {
    method: "GET",
  };

  return fetch(`${endpoint}/users/email/verify/${token}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
};

export const documentService = {
  fetchDocumentMeta,
  fetchPdfData,
  sentMail,
  updateDocument,
  fetchSignatures,
  changePassword,
  listDocument,
  listDocumentsForDashboard,
  listDocumentInformation,
  deleteDocument,
  fetchAccountVerification,
};
