import React, { useEffect, useState } from "react";
import Annotation from "../Annotation/Annotation";
import "./AnnotationWrapper.css";

const xDiff = 30;
const yDiff = 30;

const AnnotationWrapper = (props) => {
  const [reference, setReference] = useState(null);

  useEffect(() => {
    if (props.data) {
      console.log("With Sub ", props.data);
      const ref = props.data.subAnnotations.filter((data) => data.x === 0);
      let newRef = { ...ref[0] };
      if (newRef) {
        newRef.x = props.data.x;
        newRef.y = props.data.y;
        newRef.assigneeName = props.data.assigneeName;
        // newRef.imageBase64 = props.imageBase64;
      }
      
      console.log("Reference is ----->", ref);
      setReference(newRef);
    }
  }, [props]);

  return reference ? (
    <div
      style={{
        ...props.style,
        left: props.data.x * (props.pageWidth / props.renderingWidth) - xDiff,
        // top: props.data.y * (props.pageHeight / 631.3)
        top:
          (((props.renderingHeight * props.pageWidth) / props.renderingWidth) *
            props.data.y) /
            props.renderingHeight -
          yDiff,

        display: "table",
      }}
    >
      <div className="centerd">
        <Annotation
          email={props.email}
          data={reference}
          key={props.index + 1}
          index={props.index}
          setCurrentAnnotation={props.setCurrentAnnotation}
          showCanvas={props.showCanvas}
          updateAnnotation={props.updateAnnotation}
          pageWidth={props.pageWidth}
          pageHeight={props.pageHeight}
          renderingWidth={props.renderingWidth}
          renderingHeight={props.renderingHeight}
          page={props.pageNumber}
          // style={{ position: "relative" }}
          status={props.status}
        />
        {props.data.subAnnotations
          .filter((data) => data.x !== 0)
          .map((item, index) => {
            return (
              <Annotation
                email={props.email}
                data={item}
                key={props.index + index + 2}
                index={props.index}
                setCurrentAnnotation={props.setCurrentAnnotation}
                showCanvas={props.showCanvas}
                updateAnnotation={props.updateAnnotation}
                pageWidth={props.pageWidth}
                pageHeight={props.pageHeight}
                renderingWidth={props.renderingWidth}
                renderingHeight={props.renderingHeight}
                page={props.pageNumber}
                status={props.status}
              />
            );
          })}
      </div>
    </div>
  ) : null;
};

export default AnnotationWrapper;
