import React, { useState } from "react"
import Header from "../Header/header";
import Modal from "react-modal";
import { contact } from "../../services/ContactService";
import Loader from "../Loader/loader";
import { useHistory } from "react-router-dom";


const Coantact = () => {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const loginModalStyle = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 60,
            padding: 0,
            // height: 550,
            // backgroundColor: 'rgb(33, 25, 25/ 75%)'
            backgroundColor: "#E9E9E9",
        },
        overlay: {
            background: "rgba(0,0,0,0.7)",
        },
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "600px",
            backgroundColor: "#E9E9E9",
            // height: "325px",
            // textAlign: "center",
            // padding: "55px"
        },
        overlay: {
            background: "rgba(0,0,0,0.7)",
        },
    };
    async function submit(e) {
        e.preventDefault();
        console.log(name);
        console.log(message);
        console.log(email);
        try {
            setIsLoading(true);
            const response = await contact(name, email, message);
            setIsLoading(false);
            console.log(response)
            // if (!response.user.isActive) {
            //     setMessage("Plese verify your account");
            //     setError(true);

            // } else {
            //     window.localStorage.setItem("auth_token", response.token);
            //     window.localStorage.setItem("email", email);
            //     history.push("/dashboard");
            // }
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            // setMessage(e);
            // setError(true);
        }
    }
    return (
        <>
            <Header showLogout={true} />
            <div className="flex items-center min-h-full bg-gray-50 dark:bg-gray-900" style={{ position: "relative", top: "50px" }}>
                <div className="container mx-auto">
                    <div className="max-w-md mx-auto my-10 bg-white p-5 rounded-md shadow-sm">
                        <div className="text-center">
                            <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Contact Us</h1>
                        </div>
                        <div className="m-7">
                            <form onSubmit={submit}>
                                <div className="mb-6">
                                    <label htmlFor="name" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Full Name</label>
                                    <input type="text" name="name" id="name" placeholder="John Doe" onChange={(e) => { setName(e.target.value.trim()) }} required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
                                    <input type="email" name="email" id="email" placeholder="you@company.com" onChange={(e) => { setEmail(e.target.value.trim()) }} required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="message" className="block mb-2 text-sm text-gray-600 dark:text-gray-400">Your Message</label>
                                    <textarea rows={5} name="message" id="message" placeholder="Your Message" onChange={(e) => { setMessage(e.target.value.trim()) }} className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
                                </div>
                                <div className="mb-6">
                                    <button type="submit" className="w-full px-3 py-2 text-white bg-blue-400 rounded-md focus:bg-indigo-600 focus:outline-none hover:bg-blue-700" style={{ backgroundColor: "rgb(55, 140, 242);", }} onClick={() => {   setIsLoading(false)?setShow(true):setShow(false) }}>Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal
                    style={customStyles}
                    isOpen={show}
                    onRequestClose={() => {
                        setShow(false);
                    }}
                >
                    <div >
                        <header className="flex flex-col justify-center items-center  text-green-600">
                            <div className="flex justify-center  border-4 border-green-600 rounded-full mb-4">
                                <svg className="fill-current w-20" viewBox="0 0 20 20">
                                    <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" />
                                </svg>
                            </div>
                            <h2 className="font-semibold text-2xl">Success</h2>
                        </header>
                        <main className="p-3 text-center">
                            <p>
                                You are very important to us, all information received will always remain confidential. We will contact you as soon as we review your message.
                             </p>
                        </main>
                        <footer className="flex justify-center bg-transparent">
                            <button className="bg-green-600 font-semibold text-white py-3 w-full rounded-b-md hover:bg-green-700 focus:outline-none focus:ring shadow-lg hover:shadow-none transition-all duration-300"
                                onClick={() => {
                                    setShow(true)
                                    history.push("/dashboard")
                                }}>
                                Close
                             </button>
                        </footer>
                    </div>
                </Modal>
            </div>
            <Modal
                autoFocus={false}
                isOpen={isLoading}
                style={loginModalStyle}
                onRequestClose={() => {
                    setIsLoading(false);
                }}
            >
                <Loader />
            </Modal>
        </>
    )
}
export default Coantact;