import React, { useEffect, useState } from "react";
import moment from "moment";
import auditPdfDownload_Icon from "../../images/auditPdfDownload_Icon.svg";
import { documentService } from "../../services/documentService";

export const DocumentHistory = (props) => {
  const [isLoader, setIsLoader] = useState(false);

  const clickEvent = async () => {
    setIsLoader(true);
    const pdf = await documentService.fetchPdfData(props.documentId);
    setIsLoader(false);
    if (pdf.message === "Success") {
      var a = document.createElement("a"); //Create <a>
      a.href = "data:PDF;base64," + pdf.data; //Image Base64 Goes here
      a.download = "collection.PDF"; //File name Here
      a.click();
    } else {
      alert("Please add your sign to submit this document");
    }
  };

  return (
    <div
      className=" email-modal-histroy"
      style={{
        paddingLeft: 0, paddingRight: 0, minHeight: "100%", 
        maxHeight: "55vh"
      }}
    >
      <div
        class="row"
        style={{
          fontWeight: 600,
          textAlign: "center",
          fontSize: 18,
          color: "#2D3645",
        }}
      >
        <div class="col-sm-4"></div>
        <div class="col-sm-4">Audit Trail</div>
        <div
          class="col-sm-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {!isLoader ? (
            <button
              onClick={clickEvent}
              style={{
                backgroundColor: "#1088E7",
                height: 34,
                width: 126,
                borderRadius: 4,
                color: "#FFFFFF",
                fontSize: 15,
                display: "flex",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Download
              <img
                src={auditPdfDownload_Icon}
                width="17"
                height="24"
                style={{ marginLeft: 20 }}
              />
            </button>
          ) : (
            <button
              style={{
                opacity: 0.5,
                backgroundColor: "#1088E7",
                height: 34,
                width: 126,
                borderRadius: 4,
                color: "#FFFFFF",
                fontSize: 15,
                display: "flex",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "not-allowed",
              }}
            >
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </button>
          )}
        </div>
      </div>

      <div id="table">
        <table className="text-left w-full" id="table1">
          <thead
            className="mt-4 bg-gray-50 flex w-full"
            style={{ backgroundColor: "white", borderRadius: 10 }}
          >
            <tr
              className="flex w-full mb-4"
              style={{ position: "relative", top: 12 }}
            >
              <th
                scope="col"
                className="pl-4 pr-8 pb-0 w-4/12 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
                style={{ fontWeight: 800, color: "#2D3645", size: "17px" }}
              >
                Name
              </th>
              <th
                scope="col"
                className="pl-12 pr-8 pb-0 w-3/12 text-right text-xs font-bold text-gray-700 uppercase tracking-wider"
                style={{ fontWeight: 800, color: "#2D3645", size: "17px" }}
              >
                Status
              </th>
              <th
                scope="col"
                className="pl-16 pr-8 pb-0 w-5/12 text-right text-xs font-bold text-gray-700 uppercase tracking-wider"
                style={{ fontWeight: 800, color: "#2D3645", size: "17px" }}
              >
                Date & Time
              </th>
            </tr>
          </thead>

          <tbody
            className="bg-white flex flex-col divide-y divide-gray-200 items-left justify-left overflow-y-auto w-full"
            style={{
              borderRadius: 10,
              position: "relative",
              top: 10,
              overflowY: "hidden",
            }}
          >
            {props.data.map((item) => {
              return (
                <tr className="flex w-full mb-4">
                  <td className="pl-4 pr-12 pt-4 w-4/12 text-left text-sm text-gray-500 audit_trial_text_size_xl">
                    <div className="text-left">
                      {/* <div className="ml-4"> */}
                      <div className="  font-medium text-gray-900 ">
                        {item.user_name}
                      </div>
                      <div className="  text-gray-500 ">
                        {item.user_email}
                      </div>
                      <div
                        className=" font-weight-bolder text-blue-600 "
                        style={{
                          display: item.role === "CREATOR" ? "" : "none",
                        }}
                      >
                        {item.role}
                      </div>
                      {/* </div> */}
                    </div>
                  </td>
                  <td
                    className="pl-12 pr-12 pt-4 w-3/12 text-left text-sm text-gray-500 audit_trial_text_size_xl"
                    style={{ paddingLeft: 90 }}
                  >
                    <div className="  font-weight-bold text-green-800">
                      {item.document_status}
                    </div>
                  </td>
                  <td
                    className="pl-20 pt-4 w-5/12 text-left text-sm text-gray-500 audit_trial_text_size_xl"
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      paddingRight: 20,
                    }}
                  >
                    <div className="items-start pl-6 text-left">
                      <div className="text-left pl-2   font-medium text-gray-900">
                        {moment(item.updatedAt).format("DD-MMM-YYYY")}
                      </div>
                      <div className="pl-2 text-left  text-gray-500">
                        {moment(item.updatedAt).format("hh:mm:ss a")}
                      </div>
                      <div className="pl-2 text-left   text-gray-500">
                        {item.device_info}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center" style={{ marginTop: 20 }}>
        <button
          className="btn btn-primary"
          onClick={props.close}
          style={{ height: 37, width: 90 }}
        >
          Done
        </button>
      </div>
    </div>
  );
};

// import React, { useEffect, useState } from "react";
// import moment from "moment";
// import auditPdfDownload_Icon from "../../images/auditPdfDownload_Icon.svg";
// import { documentService } from "../../services/documentService";

// export const DocumentHistory = (props) => {
//   const [isLoader, setIsLoader] = useState(false);

//   const clickEvent = async () => {
//     setIsLoader(true);
//     const pdf = await documentService.fetchPdfData(props.documentId);
//     setIsLoader(false);
//     if (pdf.message === "Success") {
//       var a = document.createElement("a"); //Create <a>
//       a.href = "data:PDF;base64," + pdf.data; //Image Base64 Goes here
//       a.download = "collection.PDF"; //File name Here
//       a.click();
//     } else {
//       alert("Please add your sign to submit this document");
//     }
//   }

//   return (
//     <>
//       <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
//         <div class="row" style={{ fontWeight: 600, textAlign: "center", fontSize: 18, color: "#2D3645" }}>
//           <div class="col-sm-4"></div>
//           <div class="col-sm-4">Audit Trail</div>
//           <div class="col-sm-4" style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'flex-end'
//           }}>

//             {!isLoader ?
//               <button
//                 onClick={clickEvent}
//                 style={{
//                   backgroundColor: "#1088E7",
//                   height: 34,
//                   width: 126,
//                   borderRadius: 4,
//                   color: "#FFFFFF",
//                   fontSize: 15,
//                   display: "flex",
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center'
//                 }}>
//                 Download
//                 <img
//                   src={auditPdfDownload_Icon}
//                   width="17"
//                   height="24"
//                   style={{ marginLeft: 20 }}
//                 />
//               </button>
//               :
//               <button
//                 style={{
//                   opacity: 0.5,
//                   backgroundColor: "#1088E7",
//                   height: 34,
//                   width: 126,
//                   borderRadius: 4,
//                   color: "#FFFFFF",
//                   fontSize: 15,
//                   display: "flex",
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   cursor: 'not-allowed'
//                 }}>
//                 <i
//                   class="fa fa-circle-o-notch fa-spin"
//                 ></i>
//               </button>
//             }
//           </div>
//         </div>
//       </div>

//       <div className="container" style={{ marginTop: "19px" }}>
//         <div class="row" style={{ backgroundColor: "white", borderRadius: 10, height: "57px" }}>
//           <div class="col-sm-4">Name</div>
//           <div class="col-sm-4">Status</div>
//           <div class="col-sm-4">Date & Time</div>
//         </div>
//       </div>

//       <div className="container" style={{
//         marginTop: "12px",
//         borderRadius: 10,
//         backgroundColor: "white",
//         overflowY: 'auto'
//       }}>

//         <div
//           class="row"
//           style={{ height: 400 }}
//         >
//           {props.data.map((item) => {
//             return (
//               <>
//                 <div class="col-sm-4">
//                   <p>{item.user_name}</p>
//                   <p>{item.user_email}</p>
//                   <p>{item.role}</p>
//                 </div>
//                 <div class="col-sm-4">{item.document_status}</div>
//                 <div class="col-sm-4">
//                   <p>{moment(item.updatedAt).format("DD-MM-YYYY")}</p>
//                   <p>{moment(item.updatedAt).format("hh:mm:ss a")}</p>
//                   <p>{item.device_info}</p>
//                 </div>
//               </>
//             )
//           })}
//         </div>
//       </div>

//       <div className="flex justify-center"
//         style={{
//           marginTop: 20,
//           /* marginTop: "36px",
//           marginBottom: "20px"  */
//         }}>
//         <button className="btn btn-primary" onClick={props.close} style={{ height: 37, width: 90 }}>
//           Done
//         </button>
//       </div>
//     </>

//   );
// };
