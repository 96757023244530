import React from 'react';
import { Route, Redirect } from "react-router-dom";


export const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return localStorage.getItem('auth_token') ? <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />

            }}
        />)
}