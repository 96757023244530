import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import FromUrl from "./components/FromURL/fromURL";
import { documentService } from "./services/documentService";
import "./output.css";

import "./App.css";

function App(props) {
  const { match, location } = props;
  const [document, setDocument] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [metaId, setMetaId] = useState();
  const [metaData, setMetaData] = useState();
  const [documentStatus, setDocumentStatus] = useState();
  const [documentId, setDocumentId] = useState();

  useEffect(() => {
    const fetchDocument = async () => {
      const response = await documentService.fetchDocumentMeta(
        // location.pathname.substr(1)
        match.params.documentId
      );
      setDocument(response.documentInfo.document);
      setEmail(response.documentInfo.user_email);
      setName(response.documentInfo.user_name);
      setMetaId(response.documentInfo.id);
      setMetaData(response?.documentInfo?.document);

      // response.documentInfo.document.annotation
      setDocumentStatus(response.documentInfo.document_status);

      setDocumentId(response.documentInfo.document_id);
    };
    fetchDocument();
  }, []);

  return (
    <div>
      <div>
        {/* {document && <div>Hi</div>} */}
        {document && email && documentStatus && (
          <FromUrl
            metaData={metaData}
            annotations={document.annotation}
            name={document.originalname}
            url={document.url}
            document_id={document.id}
            email={email}
            name={name}
            id={metaId}
            //change name after app update
            isDeclined={document.isDeclined}
            declinedBy={document.declinedBy}
            isArchive={document.isArchive}
            isVoid={document.isVoid}
            isOrder={document.isOrder}
            document_status={document.document_status}
            members={[...document.members]}
            renderingWidth={document.renderingWidth}
            renderingHeight={document.renderingHeight}
            status={documentStatus}
            documentId={documentId}
            showLogout={
              new URLSearchParams(location.search).get("redirect") === "true"
            }
          />
        )}
      </div>
    </div>
  );
}

export default App;
