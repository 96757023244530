import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import PasswordReset from "./components/Password Reset/passwordReset";
import AccountVerification from "./components/account-verification/AccountVerification";
import { Login } from "./components/Login/login";
import { Dashboard } from "./components/Dashboard/dashboard";
import { AuthenticatedRoute } from "./components/AuthenticatedRoutes/authenticatedRoutes";
import { PasswordResetPage } from "./components/PasswordResetPage/passwordResetPage";
import Coantact from "./components/Contact Form/Contact";



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path="/"> <Redirect to="/login"></Redirect></Route>
      <Route exact={true} path="/verify/:token" component={PasswordReset} />
      <Route exact={true} path="/account-verification/:token" component={AccountVerification} />
      <Route exact={true} path="/document/:documentId" component={App} />
      <Route
        exact={true}
        path="/reset-password"
        component={PasswordResetPage}
      />
      <AuthenticatedRoute
        exact={true}
        path="/dashboard"
        component={Dashboard}
      />
      <Route exact={true} path="/login" component={Login} />
      <Route exact={true} path="/contact" component={Coantact} />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
