// PRODUCTION
// export const baseUrl = `https://app.signpe.com`;
// export const endpoint = `https://a169.signpe.com/api`;
// export const endpoint = `https://io.signpe.com/api`;
// export const endpoint = `http://172.16.17.153:8089/api`;


// Staging
export const baseUrl = `https://web.signpe.techiezone.fun`;
export const endpoint = `https://signpe.techiezone.fun/api`;

// DEVLOPMENT
// export const baseUrl = `https://signapp-web.netlify.app`;
// export const endpoint = `https://web.theappauthors.com/sign-app/api`;

// LOCAL
// export const baseUrl = `http://localhost:3000`;
// export const endpoint = `https://a169.signpe.com/api`;
