import { handleResponse } from "../helpers/handleResponse";
import { endpoint } from '../helpers/constants'

export const generateUniqueString = () => {
  var ts = String(new Date().getTime()),
    i = 0,
    out = '';

  for (i = 0; i < ts.length; i += 2) {
    out += Number(ts.substr(i, 2)).toString(36);
  }

  return ('web-device-' + out);
}

export const login = async (email, password) => {
  let deviceId = await generateUniqueString()

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, deviceId }),
  };

  return fetch(`${endpoint}/users/login/web`, requestOptions).then(handleResponse);
};

export const forgotPassword = async (email) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  };

  return fetch(`${endpoint}/users/forgot-password`, requestOptions).then(
    handleResponse
  );
};
