import React, { useEffect, useState, useRef, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Modal from "react-modal";
import Canvas from "../Canvas/canvas";
import Lottie from "react-lottie";
import moment from "moment";
import pdfDownload_Icon from "../../images/pdfDownload_Icon.svg";
import info_Icon from "../../images/info_Icon.svg";
import mail_Icon from "../../images/mail_Icon.svg";
import zoomIn_Icon from "../../images/zoomIn_Icon.svg";
import zoomOut_Icon from "../../images/zoomOut_Icon.svg";
import { login } from "../../services/loginService";
import { useHistory } from "react-router-dom";
import saveDownload_Icon from "../../images/saveDownload_Icon.svg";
import saveEmail_icon from "../../images/saveEmail_Icon.svg";

import "bootstrap/dist/js/bootstrap.min.js";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import classes from "./fromURL.css";
import success from "../../images/success-animation.json";

import Annotation from "../Annotation/Annotation";
import AnnotationWrapper from "../AnnotationWrapper/AnnotationWrapper";
import { documentService } from "../../services/documentService";
import { DocumentHistory } from "../DocumentHistory/documentHistory";
import PDFViewerHeader from "../PDFViewerHeader/pdfViewerHeader";
import Loader from "../Loader/loader";
import { Error } from "../Error/error";
import { width } from "dom-helpers";
import "./index.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";

// const prefix = "https://mighty-earth-62996.herokuapp.com/";
const prefix = "";

const customStyles = {
  content: {
    position: "absolute",
    overflow: "hidden",
    borderradius: "4px",
    outline: "none",
    padding: "20px 0px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    // backgroundColor: 'rgb(33, 25, 25/ 75%)'
    backgroundColor: "#E9E9E9",
    marginTop: "30px",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const customStyle = {
  content: {
    position: "absolute",
    overflow: "hidden",
    borderRadius: "10px",
    outline: "none",
    padding: "0px 0px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    marginTop: "55px",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const completeModalStyles = {
  content: {
    position: "absolute",
    overflow: "hidden",
    outline: "none",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "#fff",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "100vh",
    width: "100%",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const scale = 1;

export const documentHistoryModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "#E9E9E9",
    marginTop: isMobile ? 0 : 50,
    overflowY: "scroll",
    zIndex: -1,
    // height: "70vh",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const loaderModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 60,
    padding: 0,
    backgroundColor: "#E9E9E9",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "transparent",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const FromUrl = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [signatures, setSignatures] = useState(undefined);
  const [annotations, setAnnotations] = useState();
  const [currentAnnotation, setCurrentAnnotation] = useState();
  const [pageWidth, setPageWidth] = useState();
  const [pageHeight, setPageHeight] = useState();

  const [docPageWidth, setdocPageWidth] = useState();
  const [docPageHeight, setdocPageHeight] = useState();
  const [numPages, setNumPages] = useState(-1);
  const [currentTab, setCurrentTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [documentInfo, setDocumentInfo] = useState(); // This is used for storing document with time stack
  const [isLoader, setIsLoader] = useState(false);
  const [isCanvasOpen, setIsCanvasOpen] = useState(false);
  const [isConditionOpen, setisConditionOpen] = useState(false);
  const [isTermsConditionAccepted, setIsTermsConditionAccepted] =
    useState(false);
  const [isDocInfoOpen, setisDocInfoOpen] = useState(false);
  const [isSignSaveOpen, setisSignSaveOpen] = useState(false);
  const [isMailOpen, setisMailOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSetInputCheck, setInputCheck] = useState(false);
  const [isDownloading, setIsDownloading] = useState(true);
  const [ifSuccess, setIfSuccess] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();
  const [documentOwner, setDocumentOwner] = useState();

  const [showSignModal, setShowSignModal] = useState(true);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [remaining, setRemaining] = useState(true);
  const [message, setMessage] = useState();
  const [isCurrentUserSigned, setIsCurrentUserSigned] = useState(false);
  const [activePageIndex, setActivePageIndex] = useState(1);
  const [userName, setUserName] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [user, setUserError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState();
  const [pendingSign, setPendingSign] = useState(false);
  const [scaleIndex, setScaleIndex] = useState(0);
  const [filtersMemberData, setFiltersMemberData] = useState(0);
  const [filtersMemberOrderData, setFiltersMemberOrdertData] = useState(0);
  const [filterMyNameData, setFilterMyNameData] = useState(0);
  const [declinedByData, setDeclinedByData] = useState(0);
  const [observerFilterData, setObserverFilterData] = useState(0);
  const [macbookwidth, setMacbookWidth] = useState(false);
  const [memberData, setMemberData] = useState(0);
  const [allMemBers, setAllMembers] = useState(props?.members);
  const scaleArr = [1.0, 1.2, 1.4, 1.6];
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const pageRef = useRef({});
  const docRef = useRef({});
  useEffect(() => {
    setAllMembers(
      allMemBers.filter((data)=>
        data.role == "CREATOR"))
      console.log(allMemBers,"allMEmeeber=========================")
    if (isMobile && isTablet == false && window?.innerWidth < 900) {
      // if (window?.innerWidth <= 900) {
      zoomOutMobile();

      adjustLayoutForSmallDevices();
    } else if (isMobile && isTablet) {
      zoomOutMobile();
    }
    if (isMacBook()) {
      setMacbookWidth(true);
    } else {
      setMacbookWidth(false);
    }
    console.log("props.isOrder", props.name);
    console.log(props.status, "props.status");
    const isFilterData = props.members.filter(
      (i) =>
        !i.isObserver &&
        (i.document_status == "RECEIVED" || i.document_status == "OPENED")
    );
    const isSortingData =
      isFilterData && isFilterData.sort((a, b) => a.orderIndex - b.orderIndex);
    setFiltersMemberData(
      isFilterData && isFilterData?.length > 0 ? isFilterData : []
    );
    setFiltersMemberOrdertData(
      props.members
        .filter(
          (i) =>
            i.document_status == "RECEIVED" ||
            (i.document_status == "OPENED" && !i.isObserver)
        )
        .sort((a, b) => a.orderIndex - b.orderIndex)
    );
    // debugger
    console.log(
      "setFiltersMemberOrdertData",
      props.members
        .filter(
          (i) =>
            i.document_status == "RECEIVED" || i.document_status == "OPENED"
        )
        .sort((a, b) => a.orderIndex - b.orderIndex)
    );
    setFilterMyNameData(
      isSortingData && isSortingData?.length
        ? isSortingData[0].user_name == props.name
        : []
    );
    // debugger
    setDeclinedByData(props.declinedBy);
    setObserverFilterData(
      props.members.filter(
        (i) =>
          !i.isObserver &&
          (i.document_status == "RECEIVED" || i.document_status == "OPENED")
      )
    );
    setMemberData(
      props.members.filter(
        (i) =>
          i.user_email == props.email &&
          !i.isObserver &&
          (i.document_status == "RECEIVED" || i.document_status == "OPENED")
      )
    );
    const fetchDocumentMeta = async () => {
      if (annotations) {
        console.log(props.members, "props.members");
        console.log(props.isOrder, "props.isorder");
        console.log(props.name, "000000000000");
        console.log((props.declinedBy !== null) == props.name, "11111111111");
        console.log(
          (props.members.sort((a, b) => a.orderIndex - b.orderIndex)[0]
            .user_name ==
            props.name) ==
            true,
          "222222222"
        );
        {
          console.log("props.isOrder", props.isOrder);
        }
        {
          console.log(
            "filtersMemberOrderData",
            props.members
              .filter(
                (i) =>
                  i.document_status == "RECEIVED" ||
                  i.document_status == "OPENED"
              )
              .sort((a, b) => a.orderIndex - b.orderIndex)
          );
        }
        // debugger
        Object.keys(annotations).forEach((page, value) => {
          const current = annotations[page];
          const resp = current.filter(
            (item) => item.assignedTo === props.email
          );

          if (resp.length > 0) {
            setShowSubmit(true);
          }

          if (current) {
            let pendingSign = current.filter(
              (i) =>
                i.assignedTo == props.email &&
                (i.imageBase64 == null || i.imageBase64 == "")
            );
            if (pendingSign.length > 0) {
              setPendingSign(true);
            }
          }
        });
      }
      if (props && props.annotations && !annotations) {
        if (typeof props.annotations === typeof "") {
          setAnnotations(JSON.parse(props.annotations));
        } else {
          setAnnotations(props.annotations);
        }
      }
    };
    fetchDocumentMeta();
    if (annotations) {
      checkRemaining();
    }
    if (props.id && !signatures) {
      fetchSignature();
    }
  }, [annotations, props]);

  function zoomOutMobile() {
    var viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
      viewport.content = "initial-scale=0.1";
      viewport.content = "width=700";
    }
  }
  function isMacBook() {
    // Check if the user agent indicates a MacBook
    return (
      /Macintosh/.test(navigator.userAgent) &&
      !/iPhone|iPad|iPod/.test(navigator.userAgent)
    );
  }
  function adjustLayoutForSmallDevices() {
    const header = document.querySelector(".pdf-viewer-header");

    if (header) {
      if (window.innerWidth < 900) {
        // Adjusting header size for small devices
        header.style.height = "150px";
        header.style.padding = "20px";
        header.style.fontSize = "40px";

        // Select and adjust sizes of images within the header
        const images = header.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "100%"; // Adjust width of images
          image.style.height = "100%"; // Adjust height of images
          // You can adjust other styles of images as needed
        });
        const auditTrailLink = header.querySelector(
          ".pdf-viewer-header .audit-trail-link a"
        );
        if (auditTrailLink) {
          console.log(auditTrailLink, "123123123154554");
          auditTrailLink.style.fontSize = "29px";
          // You can adjust other styles of the Audit Trail link as needed
        }

        // Adjusting size of the Submit link
        const submitLink = header.querySelector(
          ".pdf-viewer-header .submit-link a"
        );
        if (submitLink) {
          submitLink.style.fontSize = "24px";
          // You can adjust other styles of the Submit link as needed
        }
      } else {
        // Resetting header size for larger devices
        header.style.height = "initial";
        header.style.padding = "initial";
        header.style.fontSize = "initial";
        // Reset styles of images within the header
        const images = header.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "initial"; // Reset width of images
          image.style.height = "initial"; // Reset height of images
          // Reset other styles of images as needed
        });
      }
    }
  }

  function onDocumentLoadSuccess(data) {
    setNumPages(data.numPages);
    setPageNumber(1);
  }

  const onPageLoadSuccess = (height, width) => {
    setIsDownloading(false);

    setPageWidth(width);
    setPageHeight(height);

    // setPageWidth(width);
    // setPageHeight(height);
  };

  const onPageAndSidebarLoadSuccess = (height, width) => {
    console.log("onPageLoadSuccess width", width, height);
    setIsDownloading(false);

    setdocPageWidth(width);
    setdocPageHeight(height);
  };

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  // const doDownloadFile = async () => {

  //   setIsDownloading(true);
  //   try {
  //     let imgData = []
  //
  //     await new Promise((resolve, reject) => {
  //       html2canvas(pageRef.current[`page_1`]).then(canvas => {
  //         console.log('canvas', canvas)
  //         let data = canvas.toDataURL('image/jpeg', 1);
  //         imgData.push(data)
  //
  //         resolve()
  //       });
  //     })

  //     await new Promise((resolve, reject) => {
  //       html2canvas(pageRef.current[`page_2`]).then(canvas => {
  //         console.log('canvas', canvas)
  //         let data = canvas.toDataURL('image/jpeg', 1);
  //         imgData.push(data)
  //
  //         resolve()
  //       });
  //     })

  //     await new Promise((resolve, reject) => {
  //       html2canvas(pageRef.current[`page_3`]).then(canvas => {
  //         console.log('canvas', canvas)
  //         let data = canvas.toDataURL('image/jpeg', 1);
  //         imgData.push(data)
  //
  //         resolve()
  //       });
  //     })

  //     // for (let i = 0; i < numPages; i++) {
  //     //   await new Promise((resolve, reject) => {

  //     //     html2canvas(pageRef.current[`page_${i + 1}`], {
  //     //       onrendered: function (canvas) {
  //     //
  //     //         console.log('canvas', canvas)
  //     //         let data = canvas.toDataURL('image/jpeg', 1);
  //     //         imgData.push(data)
  //     //         resolve()
  //     //       }
  //     //     })
  //     //   })
  //     // }
  //
  //     const pdf = new jsPDF('p', 'mm', 'a4');
  //     var width = pdf.internal.pageSize.getWidth();
  //     var height = pdf.internal.pageSize.getHeight();

  //     for (let j = 0; j < numPages; j++) {
  //       pdf.addImage(imgData[j], 'JPEG', 0, 0, width, height);
  //       pdf.addPage();
  //     }
  //     pdf.deletePage(numPages + 1)
  //     // console.log('pdf --', pdf.output())
  //     pdf.save('signpe-document.pdf');
  //     setIsDownloading(false);
  //   } catch (e) {
  //     console.log('e', e)
  //
  //   }
  // }

  const backingScale = () => {
    console.log("window.devicePixelRatio", window.devicePixelRatio);
    if (window.devicePixelRatio && window.devicePixelRatio > 1) {
      return window.devicePixelRatio;
    }
    return 1;
  };

  const doDownloadFile = async () => {
    try {
      let imgData = [];
      for (let i = 0; i < numPages; i++) {
        // console.log('document.getElementById(page_1)', document.getElementById('page_1'))
        // console.log('document.getElementById(page_2)', document.getElementById('page_2'))
        // console.log('document.getElementById(page_3)', document.getElementById('page_3'))
        //
        let canvas = await html2canvas(pageRef.current[`page_${i + 1}`], {
          // scale: 2,
          dpi: 600,
        });
        // debugger
        let data = canvas.toDataURL("image/png", 1, 1);

        // debugger
        imgData.push(data);
      }
      const pdf = new jsPDF("p", "mm", "a4", true);
      var width = pdf.internal.pageSize.getWidth();

      for (let j = 0; j < numPages; j++) {
        const imgProps = pdf.getImageProperties(imgData[j]);
        const pdfHeight = (imgProps.height * width) / imgProps.width;
        pdf.addImage(imgData[j], "JPEG", 0, 0, width, pdfHeight);
        pdf.addPage();
      }
      pdf.deletePage(numPages + 1);

      pdf.save("signpe-document.pdf");
      setIsDownloading(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const fetchData = async () => {
    try {
      // setIsLoader(true);
      console.log("gayo");
      const imgDataPromises = [];
      for (let i = 0; i < numPages; i++) {
        imgDataPromises.push(
          html2canvas(pageRef.current[`page_${i + 1}`], {
            scale: 2,
            dpi: 600,
          }).then((canvas) => canvas.toDataURL("image/png", 1))
        );
      }
      const imgData = await Promise.all(imgDataPromises);
      const pdf = new jsPDF("p", "mm", "a4", true);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      imgData.forEach((image) => {
        pdf.addImage(image, "JPEG", 0, 0, width, height);
        pdf.addPage();
      });
      pdf.deletePage(numPages + 1);
      // setIsDownloading(false);
      return pdf.output("blob");
    } catch (e) {
      console.error("Error:", e);
      throw e;
    }
  };
  // async function
  const sendEmailApiData = async () => {
    setIsLoader(true);
    try {
      await fetchData().then((data) => {
        setIsLoader(true);
        documentService
          // .sentMail(email, data, props.metaData.originalname)
          .sentMail(email, data, props.metaData.originalname, props.email,allMemBers[0].user_name)
          .then((data) => {
            if (data.message === "Your email has been successfully sent.") {
              setMessage(data.message);
              setIsLoader(false);
              setShowAlert(true);
              setisMailOpen(false);
              setEmail("");
            } else {
              alert(data.message);
            }
          });

        // const [data, response] = await Promise.all([pdfBlobPromise, responsePromise]);
        // if (response || data) {

        //   setIsLoader(false);
        // }
      });
    } catch (error) {
      console.error("Error:", error);
      setIsLoader(false);
    }
  };

  const findCurrentUserAnnotationToSign = () => {
    const userAnnotations = [];
    Object.keys(annotations).forEach((page) => {
      const currentPageAnnotations = annotations[page];
      const temp = currentPageAnnotations
        .filter((annotation) => {
          if (annotation.subAnnotations) {
            const ref = annotation.subAnnotations.filter(
              (data) => data.x === 0 && data.type === "Signature"
            );
            return annotation.assignedTo === props.email;
          } else {
            return (
              annotation.assignedTo === props.email &&
              annotation.type === "Signature"
            );
          }
        })
        .map((annotation) => {
          if (annotation.subAnnotations) {
            const ref = annotation.subAnnotations.filter(
              (data) => data.x === 0 && data.type === "Signature"
            );
            return ref[0];
          } else {
            return annotation;
          }
        });

      if (temp.length > 0) {
        userAnnotations.push(...temp);
      }
    });
    console.log("User annotations are ", userAnnotations);
    return userAnnotations;
  };

  const updateAnnotation = (page, index, updatedValue) => {
    const temp = annotations;
    temp[page][index] = updatedValue;
    setAnnotations(temp);
  };

  const listDocumentInfo = async () => {
    const response = await documentService.listDocument(props.document_id);
    setDocumentInfo(response.response);
    setUserName(response.response[0].user_name);
    setUserEmail(response.response[0].user_email);

    console.log(response.response[0].user_name, "name");
    console.log(response.response[0].user_email, "email");
    console.log(response.response, "all Data");
  };
  const fetchSignature = () => {
    // console.log("Fetching Signatures");
    const temp = async () => {
      try {
        const sign = await documentService.fetchSignatures(props.id);
        setSignatures(sign["signature"]);

        // console.log(sign);
      } catch (err) {
        console.log("Failed to fetch signatures");
      }
    };
    temp();
  };

  const onSave = (data, didSign = false) => {
    if (didSign) {
      //   if (!annotations[pageNumber][currentAnnotation].subAnnotations) {
      //     annotations[pageNumber][currentAnnotation] = {
      //       ...annotations[pageNumber][currentAnnotation],
      //       imageBase64: data,
      //     };
      //   } else {
      //     annotations[pageNumber][currentAnnotation].subAnnotations[0] = {
      //       ...annotations[pageNumber][currentAnnotation].subAnnotations[0],
      //       imageBase64: data,
      //     };
      //   }

      const userAnnotations = findCurrentUserAnnotationToSign();
      userAnnotations.forEach((annotation) => {
        annotation.imageBase64 = data;
      });
      setIsCurrentUserSigned(true);
    }
    setIsCanvasOpen(false);
    checkRemaining();
  };

  const showCanvas = (index) => {
    // console.log("Canvas Opened");
    setIsCanvasOpen(true);
    // setCurrentAnnotation(index);
  };

  const finalSubmit = () => {
    const body = {
      annotations: annotations,
      documentid: props.document_id,
      email: props.email,
      renderingWidth: props.renderingWidth,
      renderingHeight: props.renderingHeight,
    };
    // console.log(JSON.stringify(body));

    temp(body);
  };
  const temp = async (body) => {
    try {
      setIsDownloading(true);
      const response = await documentService.updateDocument(body);

      setIsDownloading(false);
      if (response.message === "Success") {
        // Code for showing success
        console.log("ok");
        setIfSuccess(true);
        setisSignSaveOpen(true);
      } else {
        alert("Please add your sign to submit this document");
      }
      // console.log(response);
    } catch (e) {
      setIsDownloading(false);
    }
  };

  const checkRemaining = () => {
    Object.keys(annotations).forEach((page) => {
      const current = annotations[page];

      let remaining = false;
      remaining = current.some((value) => {
        if (value.subAnnotations) {
          const ref = value.subAnnotations.filter((data) => data.x === 0);
          if (
            ref.length > 0 &&
            ref[0].imageBase64 === "" &&
            ref[0].assignedTo === props.email
          ) {
            console.log("Remaining is --->", ref[0]);
            return true;
          }
          return false;
        } else {
          if (value.imageBase64 === "" && value.assignedTo === props.email) {
            console.log("Remaining is ---->", value);
            return true;
          }
          return false;
        }
      });
      // console.log(remaining);
      setRemaining(remaining);
    });
  };

  const renderAnnotations = (page) => {
    return annotations && annotations[page] ? (
      annotations[page].map((item, index) => {
        return !item.subAnnotations || item.subAnnotations === null ? (
          <div onClick={() => onAnnotationClick(page, index)}>
            <Annotation
              email={props.email}
              data={item}
              key={page * 1000 + index * 10 + 1}
              index={index}
              setCurrentAnnotation={setCurrentAnnotation}
              showCanvas={showCanvas}
              cursor={
                isTermsConditionAccepted && (filterMyNameData || !props.isOrder)
              }
              updateAnnotation={updateAnnotation}
              pageWidth={pageWidth}
              isVoid={props.isVoid}
              pageHeight={pageHeight}
              renderingWidth={props.renderingWidth}
              renderingHeight={props.renderingHeight}
              pagepageNumber={page}
              style={{
                position: "absolute",
                // cursor: isTermsConditionAccepted? "pointer":'not-allowed'
              }}
              scale={scaleArr[scaleIndex]}
              status={props.status}
            >
              Sign Here
            </Annotation>
          </div>
        ) : (
          <>
            {
              <div onClick={() => onAnnotationClick(page, index)}>
                <AnnotationWrapper
                  data={item}
                  email={props.email}
                  key={page * 1000 + index * 10 + 1}
                  index={page * 1000 + index * 10 + 1}
                  setCurrentAnnotation={setCurrentAnnotation}
                  showCanvas={showCanvas}
                  updateAnnotation={updateAnnotation}
                  pageWidth={pageWidth}
                  pageHeight={pageHeight}
                  renderingWidth={props.renderingWidth}
                  renderingHeight={props.renderingHeight}
                  pageNumber={page}
                  style={{ position: "absolute" }}
                  status={props.status}
                />
              </div>
            }
          </>
        );
      })
    ) : (
      <div></div>
    );
  };

  const renderPages = () => {
    const pages = [];

    for (let i = 0; i < numPages; ++i) {
      pages.push(
        <div
          className="mt-4 rendered-pages"
          style={{
            // boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 10px",
            position: "relative",
            // width: "612px",
            // marginRight: 300
          }}
          key={i + 1}
          id={`page_${i}`}
          ref={(element) => {
            if (element) {
              if (!pageRef.current[`page_${i + 1}`]) {
                pageRef.current[`page_${i + 1}`] = element;
              }
            }
          }}
        >
          {console.log("backingScale", backingScale())}
          <Page
            object-fit="fill"
            width={
              macbookwidth
                ? isBrowser && window.innerWidth < 1682
                  ? 350
                  : 595
                : isMobile && isTablet == false
                ? 350
                : isTablet && 400
            }
            onLoadSuccess={({ height, width }) => {
              onPageLoadSuccess(height, width);
            }}
            renderMode={isIOS ? "svg" : "canvas"}
            renderTextLayer={false}
            scale={backingScale()}
            className={classes.temp}
            pageNumber={i + 1}
            renderAnnotationLayer={false}
            key={i + 1}
          />
          {renderAnnotations(i + 1)}
          {/* {renderAnnotations(i + 1)} */}
        </div>
      );
    }
    return pages;
  };
  const sidebarPages = () => {
    const pages = [];

    for (let i = 0; i < numPages; ++i) {
      pages.push(
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              // right: 130,
              left: 366,
              minWidth: 22,
              height: 22,
              borderRadius: "50%",
              backgroundColor: "#1088E7",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            align="center"
          >
            <span style={{ color: "#fff", fontSize: 14 }}>{i + 1}</span>
          </div>
          <div
            className="sidebarPdf"
            style={{
              marginLeft: 400,
              border: `2px solid ${
                activePageIndex == i + 1 ? "#1088E7" : "#E2E2E2"
              }`,
              borderradius: 5,
              marginBottom: 20,
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              if (i + 1 != activePageIndex) {
                setActivePageIndex(i + 1);
                setTimeout(() => {
                  pageRef.current[`page_${i + 1}`].scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }, 400);
              }
            }}
          >
            <div
              // className="mt-2 mb-2"
              style={{
                position: "relative",
                // width: 116,
                height: 150,
                background: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                margin: "10px 15px",
              }}
              key={i + 1}
            >
              <Page
                className={[classes.temp, {}]}
                // onLoadSuccess={onPageLoadSuccess}
                pageNumber={i + 1}
                renderAnnotationLayer={false}
                height={150}
                key={i + 1}
                object-fit="fill"
                width={
                  macbookwidth
                    ? isBrowser && window.innerWidth < 1682
                      ? 50
                      : 100
                    : isMobile && 350
                }
                onLoadSuccess={({ height, width }) => {
                  onPageAndSidebarLoadSuccess(height, width);
                }}
                renderMode={isIOS ? "svg" : "canvas"}
                // onLoadSuccess={onPageLoadSuccess}
                renderTextLayer={false}
                scale={backingScale()}
              />
            </div>
          </div>
        </div>
      );
    }
    return pages;
  };
  const onAnnotationClick = (page, annotationIndex) => {
    setCurrentAnnotation(annotationIndex);
    setPageNumber(page);
  };

  const submit = () => {
    checkRemaining();
    // return;
    if (!remaining) {
      finalSubmit();
    } else {
      setMessage("Please add your sign to submit this document");
      setShowAlert(true);
    }
  };

  function toDataUrl(url, callback) {
    url = `${prefix}${url}`;
    console.log(url);
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const convertImage = (index) => {
    const image = document.getElementById(`image_${index}`);
    toDataUrl(image.src, (data) => {
      onSave(data, true);
    });
  };

  const showDocumentHistory = () => {
    console.log("Show the modal please");
    listDocumentInfo();
    setShowInfo(true);
  };
  const notification = (e) => {
    e.preventDefault();
    setisConditionOpen(true);
  };

  const EmailSent = (e) => {
    e.preventDefault();
    setisMailOpen(true);
  };

  const document_Info = (e) => {
    e.preventDefault();
    setisDocInfoOpen(true);
    listDocumentInfo();
  };

  const getUserName = (data) => {
    if (data) {
      if (props.email == data.user_email) {
        return "You";
      } else {
        return data.user_name;
      }
    }
  };
  const document_Signed = (e) => {
    e.preventDefault();
  };
  async function click(e) {
    e && e.preventDefault();
    // console.log(password);
    try {
      if (email == "" || password == "") {
        if (email == "") {
          setEmailError(
            <span style={{ color: "red", fontSize: "14px" }}>
              Email cannot be empty
            </span>
          );
        }
      } else {
        setIsLoading(true);
        const response = await login(email, password);
        setIsLoading(false);
        console.log(response);
        if (response) {
          console.log(response);
          if (!response.user.isActive) {
            setMessage("Plese verify your account");
            setError(true);
          } else {
            window.localStorage.setItem("auth_token", response.token);
            window.localStorage.setItem("email", email);
            history.push("/dashboard");
          }
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      if (e.message) {
        setUserError(
          <span style={{ color: "red", fontSize: "15px", paddingLeft: "3px" }}>
            No such email exists
          </span>
        );
      } else {
        setUserError();
      }
    }
  }
  const handleInputChange = useCallback(() => {
    setInputCheck((prevState) => !prevState);
  }, []);

  return (
    <div className="min-h-screen" style={{ width: "100%" }}>
      {!isSignSaveOpen && (
        <div style={{ display: isDownloading ? "none" : "" }}>
          <PDFViewerHeader
            isVoid={props.isVoid}
            isArchive={props.isArchive}
            isOrder={props.isOrder}
            isDeclined={props.isDeclined}
            text={props.metaData.originalname}
            showLogout={props.showLogout}
            showDocumentHistory={showDocumentHistory}
            isCurrentUserSigned={isCurrentUserSigned}
            status={props.status}
            document_status={props.document_status}
            onSubmit={submit}
          />
        </div>
      )}
      {console.log("props.status", props.status)}
      {/* {console.log('props.status', props.status)} */}
      <div
        className="flex flex-wrap bg-gray-100 w-full h-screen"
        style={{ backgroundColor: "#F2F4F7" }}
      >
        {/* {!isSignSaveOpen && !isTermsConditionAccepted && !showInfo && <div className="container w-full" */}
        {pendingSign && !isDownloading && !isTermsConditionAccepted && (
          <div
            className=" additionalDiv"
            style={{
              // backgroundColor: "#FFFFFF",
              clear: "both",
              textalign: "center",
              fontSize: 14,
              // height: 60,
              left: 0,
              right: 0,
              // top: 70,
              alignSelf: "center",
              position: "fixed",
              overflow: "hidden",
              whitespace: "nowrap",
              width: "100%",
              zIndex: 5,
              // padding: 12,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 0px 30px 0px",
              // props.isArchive == true && props.isVoid == true ?
              // borderRadius: 5,
              // boxShadow: "0px 3px 6px #5B7BAB29",
            }}
          >
            <div
              style={{
                width: isMobile ? "80%" : "auto",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 3px 6px #c0c7d4 ",
                padding: "5px 30px",
                borderRadius: isMobile ? 20 : 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  checked={isSetInputCheck}
                  onChange={handleInputChange}
                  style={{
                    background: "transparent",
                    border: "1px solid #2D3645",
                    borderRadius: 2,
                    opacity: 1,
                    height: 18,
                    width: 18,
                    // position: "absolute",
                    // top: 21,
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // left: 10
                  }}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  for="scales"
                  style={{
                    // fontSize: window.innerWidth > 1500 ? "30px" : "14px",
                    padding: "10px 0px 4px 10px",
                    // marginLeft: 26,
                    // position: "absolute",
                    // fontFamily: "product Sans", "Regular"
                  }}
                >
                  I confirm that I have read and understood the &nbsp;
                  <a
                    href="https://signpe.com/terms-of-use/"
                    target="_blank"
                    // onClick={notification}
                    style={{
                      textAlign: "left",
                      textDecoration: "underline",
                      letterspacing: 0.33,
                      color: "#2D3645",
                      fontWeight: "bold",
                    }}
                  >
                    "Electronic Record and Signature Disclosure"
                  </a>
                  &nbsp; and consent to use electronic records and signatures.
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn btn notification"
                  type="button"
                  onClick={() =>
                    isSetInputCheck &&
                    setIsTermsConditionAccepted(true) &&
                    setisConditionOpen(true)
                  }
                  style={{
                    backgroundColor: "#1088E7",
                    color: "#FFFFFF",
                    width: 146,
                    height: 28,
                    marginLeft: 35,
                    cursor: isSetInputCheck ? "pointer" : "not-allowed",
                    fontSize: 14,
                    paddingTop: 2,
                    borderradius: 2,
                    opacity: 1,
                  }}
                >
                  Agree & Continue
                </button>
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={pendingSign && !isDownloading && !isTermsConditionAccepted}
          style={customStyles}
          onRequestClose={() => setPendingSign(true)}
        ></Modal>

        <div
          className="w-3/12 bg-white rounded p-3 shadow-lg div-one"
          style={{
            marginTop: 10,
            width: "25%",
            // height: "120vh",
            height: "calc(100vh - 64px)",

            overflowX: "auto",
            position: "fixed",
          }}
        >
          <div
            style={{
              position: "relative",
              display: isDownloading ? "none" : "flex",
              justifyContent: "center",
              width: 0,
            }}
          >
            {props.url ? (
              <Document
                className={classes.temp}
                file={`${prefix}${props.url}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {/* <Page pageNumber={3} /> */}
                {sidebarPages()}
              </Document>
            ) : (
              <h1>Loading</h1>
            )}
          </div>
        </div>

        <div
          className=" div-two"
          style={{
            overflow: " ",
            position: "relative",
            // left: window.innerWidth > 1488 ? "22%" : "",
          }}
        >
          <div className="  pt-4" style={{ marginLeft: 0 }}>
            <div className="  my-3" style={{ overflow: " " }}>
              <div
                className="noticepage"
                style={
                  {
                    // margin: "auto",
                    // marginTop: window.innerWidth > 900 ? 65 : 165,
                  }
                }
              >
                {!isDownloading &&
                  !isConditionOpen &&
                  !isSignSaveOpen &&
                  !showInfo &&
                  !isCanvasOpen &&
                  !isMailOpen && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="modal-icon"
                        style={{
                          backgroundColor: "blue",
                          borderRadius: 6,
                          height: isMobile && isTablet == false ? 70 : 50,
                          width: isMobile && isTablet == false ? 350 : 228,
                          padding: isMobile && isTablet == false ? 10 : 0,
                          color: "white",
                          background: "#FFFFFF",
                          boxShadow: "0px 3px 6px #5B7BAB29",
                          opacity: 1,
                          display: "flex",
                          marginTop:
                            isDownloading ||
                            (props.isArchive == "true" &&
                              props.isVoid == "true") ||
                            props.isVoid == "true" ||
                            props.isDeclined === "true" ||
                            props.status === "SENT" ||
                            props.status === "OPENED"
                              ? -60
                              : -60,
                          zIndex: !isDocInfoOpen ? 0 : -1,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt=""
                          onClick={(e) => {
                            document_Info(e);
                          }}
                          src={info_Icon}
                          width="20"
                          height="20"
                          style={{
                            cursor: "pointer",
                            marginLeft: 12,
                            height:
                              isMobile && isTablet == false ? "50px" : "20px",
                            width:
                              isMobile && isTablet == false ? "50px" : "20px",
                          }}
                        />
                        {console.log("scaleIndex", scaleIndex)}
                        {/* <img
                    onClick={() => {
                      if (scaleIndex == scaleArr.length - 1) {
                        return
                      }
                      let index = scaleIndex
                      index += 1
                      setScaleIndex(index)
                    }}
                    src={zoomIn_Icon}
                    width="20"
                    height="20"
                    style={{
                      marginLeft: 20,
                      opacity: scaleIndex == scaleArr.length - 1 ? 0.5 : 1,
                      cursor: scaleIndex == scaleArr.length - 1 ? 'not-allowed' : 'pointer',
                    }}
                  />
                  <img
                    onClick={() => {

                      if (scaleIndex == 0) {
                        return
                      }
                      let index = scaleIndex
                      index -= 1
                      setScaleIndex(index)

                    }}
                    src={zoomOut_Icon}
                    width="20"
                    height="20"
                    style={{
                      marginLeft: 24,
                      opacity: scaleIndex == 0 ? 0.5 : 1,
                      cursor: scaleIndex == 0 ? 'not-allowed' : 'pointer',
                    }}
                  /> */}
                        <img
                          alt=""
                          src={pdfDownload_Icon}
                          width="20"
                          height="20"
                          style={{
                            marginLeft: isMobile && isTablet == false ? 50 : 30,
                            cursor: "pointer",
                            height:
                              isMobile && isTablet == false ? "46px" : "19px",
                            width:
                              isMobile && isTablet == false ? "50px" : "20px",
                          }}
                          onClick={(e) => {
                            doDownloadFile(e);
                          }}
                        />
                        {/* <a href="mailto:?subject=SignPe&body=" target="_blank"> */}
                        <img
                          alt=""
                          onClick={(e) => {
                            EmailSent(e);
                          }}
                          src={mail_Icon}
                          width="20"
                          height="20"
                          style={{
                            marginLeft: isMobile && isTablet == false ? 50 : 30,
                            cursor: "pointer",
                            height:
                              isMobile && isTablet == false ? "50px" : "20px",
                            width:
                              isMobile && isTablet == false ? "50px" : "20px",
                          }}
                        />
                        {/* </a> */}
                      </div>
                    </div>
                  )}
                {/* {props.status === "SENT" && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: 10,
                      marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    This document is submitted
                  </div>
                )} */}
                {props.isArchive == true && props.isVoid == true ? (
                  <div
                    style={{
                      color: "red",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    This document is archived!
                  </div>
                ) : props.isArchive === true ? (
                  <div
                    style={{
                      color: "red",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    This document is archived!
                  </div>
                ) : props.isVoid == true ? (
                  <div
                    style={{
                      color: "#F65843",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    This document has been void.
                  </div>
                ) : (declinedByData !== null &&
                    props.name == declinedByData.name) == true ? (
                  <div
                    style={{
                      color: "#F65843",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    You have declined this document.
                  </div>
                ) : declinedByData !== null ? (
                  <div
                    style={{
                      color: "#F65843",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    This document is declined by <b>{declinedByData.name}</b>.
                  </div>
                ) : !props.isOrder && memberData.length == 1 ? (
                  // filtersMemberOrderData[0].user_name == props.name ?
                  // props.status == "OPENED" ?
                  <div
                    className="wait-sign"
                    style={{
                      fontSize: isMobile && isTablet == false ? "40px" : "14px",
                      color: "#2196F3",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    Waiting for you to sign.
                  </div>
                ) : !props.isOrder &&
                  filtersMemberData &&
                  filtersMemberData?.length == 1 ? (
                  <div
                    style={{
                      color: "#2196F3",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    Waiting for{" "}
                    <b>
                      {filtersMemberData && filtersMemberData?.length == 1
                        ? getUserName(filtersMemberOrderData[0])
                        : ""}
                    </b>{" "}
                    to sign.
                  </div>
                ) : !props.isOrder && observerFilterData.length > 0 ? (
                  <div
                    style={{
                      color: "#2196F3",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    {console.log("!props.isOrder", !props.isOrder)}
                    {console.log(
                      "!filtersMemberOrderData",
                      !filtersMemberOrderData
                    )}
                    Waiting for {observerFilterData.length} signers to sign.
                    {/* Waiting for {filtersMemberData && filtersMemberData?.length > 0 ? filtersMemberData?.length : 0} signers to sign. */}
                  </div>
                ) : props.isOrder && filtersMemberOrderData.length > 0 ? (
                  <div
                    style={{
                      color: "#2196F3",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    {console.log("props.isOrder", props.isOrder)}
                    {console.log(
                      "filtersMemberOrderData",
                      filtersMemberOrderData
                    )}
                    Waiting for{" "}
                    {filtersMemberOrderData.length > 0 &&
                    props.email == filtersMemberOrderData[0].user_email ? (
                      "you"
                    ) : (
                      <b>{filtersMemberOrderData[0].user_name}</b>
                    )}{" "}
                    to sign.
                    {/* </div> : filterMyNameData?
                          // filtersMemberOrderData[0].user_name == props.name ?
                          // props.status == "OPENED" ?
                          <div
                            style={{
                              color: "#2196F3",
                              // fontWeight: "bold",
                              textAlign: "center",
                              marginTop: 10,
                              marginRight: 410,
                              display: isDownloading ? "none" : "",
                            }}
                          >
                            Waiting for you to sign. */}
                  </div>
                ) : filtersMemberData.length == 0 ? (
                  <div
                    style={{
                      color: "#24A55B",
                      // fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 10,
                      // marginRight: 410,
                      display: isDownloading ? "none" : "",
                    }}
                  >
                    This document is submitted.
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="responsive-div"
                  id="doc-ref-id "
                  style={{
                    position: "relative",
                    // display: isDownloading ? "none" : "flex",
                    justifyContent: "center",
                    // marginRight: 90,
                    height: "100vh",
                    // overflowX: 'hidden',
                  }}
                  ref={(element) => {
                    docRef.current[`doc-ref-id`] = element;
                  }}
                >
                  {props.url ? (
                    <>
                      {console.log(
                        "`${prefix}${props.url}`",
                        `${prefix}${props.url}`
                      )}
                      <Document
                        className={classes.temp}
                        file={`${prefix}${props.url}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onItemClick={(as) => alert("hi", as)}
                      >
                        {renderPages()}
                      </Document>
                      {/* <button onClick={() => {
                        console.log(pageRef.current['page_5'])
                        pageRef.current['page_5'].scrollIntoView();
                        // window.scrollTo(0, pageRef.current['page_5'].offsetTop)
                      }}>
                        Next page
                      </button> */}
                    </>
                  ) : (
                    <h1>Loading</h1>
                  )}
                </div>

                {console.log("props.status", props.status)}

                {/* {!isDownloading &&
                !props.isVoid &&
                !props.isArchive &&
                !props.isDeclined ? (
                  <div className="row" style={{ marginTop: 30 }}>
                   

                    <div
                      className="col-sm-7"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        position: "fixed",
                        bottom: 0,
                        width: "100%",
                        marginBottom: 20, // Adjust this value as needed
                      }}
                    >
                      {props.status !== "SENT" && isCurrentUserSigned && (
                        <button
                          className="btn font-bold"
                          onClick={submit}
                          style={{
                            color: "white",
                            backgroundColor: "#2DA562",
                            borderRadius: 4,
                            paddingRight: 25,
                            paddingLeft: 25,
                            display: isDownloading ? "none" : "",
                          }}
                        >
                          SUBMIT
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )} */}

                <Modal
                  isOpen={isCanvasOpen}
                  style={customStyles}
                  onRequestClose={() => setIsCanvasOpen(false)}
                >
                  <div
                    className="bg-white"
                    align="center"
                    style={{
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    <nav
                      className="flex flex-col sm:flex-row custom-sign-modal-nav"
                      style={{
                        alignContent: "center",
                        display: "flex",
                        justifyContent: "space-evenly",
                        padding: "0px 100px",
                        marginTop: "-30px",
                      }}
                    >
                      <button
                        className={`text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none  ${
                          currentTab === 0
                            ? "border-b-2 font-medium border-blue-500"
                            : ""
                        }`}
                        onClick={() => {
                          if (!signatures) {
                            fetchSignature();
                          }
                          setShowSignModal(true);
                          setCurrentTab(0);
                        }}
                        style={{ color: "black", fontWeight: 400 }}
                      >
                        Saved Signatures
                      </button>
                      <button
                        className={`text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none  ${
                          currentTab === 1
                            ? "border-b-2 font-medium border-blue-500"
                            : ""
                        }`}
                        onClick={() => {
                          setShowSignModal(false);
                          setCurrentTab(1);
                        }}
                        style={{ color: "black", fontWeight: 400 }}
                      >
                        New Signature
                      </button>
                    </nav>
                  </div>

                  <div className="container">
                    <div
                      className="row"
                      style={{
                        height: "100%",
                        margin: "0px",
                        minHeight: isMobile ? "648px" : "441px",
                      }}
                    >
                      {!showSignModal ? (
                        <Canvas onSave={onSave} />
                      ) : signatures && signatures.length > 0 ? (
                        signatures.map((item, index) => {
                          return (
                            <div
                              className="col-lg-3 col-md-3 col-sm-3 col-xs-12"
                              style={{ padding: 15 }}
                            >
                              <img
                                alt="Sign Here"
                                id={`image_${index}`}
                                key={index}
                                src={item.url}
                                style={{
                                  // border: "solid 1px",
                                  backgroundColor: "white",
                                  width: 160,
                                  height: 180,
                                  // marginLeft: 15,
                                  borderBottomLeftRadius: 5,
                                  borderBottomRightRadius: 5,
                                  borderTopLeftRadius: 5,
                                  borderTopRightRadius: 5,
                                }}
                                onClick={() => convertImage(index)}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <h1>No Signature Found</h1>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* </div> */}
                </Modal>
                <Modal
                  isOpen={isDownloading}
                  style={loaderModalStyle}
                  onRequestClose={() => {
                    setIsDownloading(false);
                  }}
                >
                  <Loader />
                </Modal>
                <Modal isOpen={ifSuccess} style={customStyles1}>
                  <Lottie
                    options={defaultOptions}
                    height={250}
                    width={250}
                    // eventListeners={[
                    //   {
                    //     eventName: "complete",
                    //     callback: () => window.location.reload(),
                    //   },
                    // ]}
                  />
                </Modal>

                {documentInfo && (
                  <Modal
                    isOpen={showInfo}
                    style={documentHistoryModalStyle}
                    onRequestClose={() => {
                      setShowInfo(false);
                    }}
                  >
                    {documentInfo && (
                      <DocumentHistory
                        documentId={props.document_id}
                        data={documentInfo}
                        close={() => setShowInfo(false)}
                      />
                    )}
                  </Modal>
                )}
                {console.log("props.document_id", props.document_id)}
                <Error
                  show={showAlert}
                  status={"success"}
                  message={message}
                  duration={4000}
                  position={{ vertical: "top", horizontal: "right" }}
                  onClose={() => setShowAlert(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={isConditionOpen}
        style={customStyle}
        onRequestClose={() => setisConditionOpen(true)}
      >
        {!isTermsConditionAccepted && (
          <div
            className="box w-full"
            style={{
              width: 910,
              height: 580,
              backgroundColor: "#FFFFFF",
              borderRadius: 10,
            }}
          >
            <div
              className="show"
              style={{
                textalign: "left",
                width: 910,
                height: 65,
                position: "relative",
                font: "normal",
                color: "#FFFFFF",
                paddingLeft: 30,
                paddingTop: 21,
                fontWeight: 500,
                opacity: 1,
                background: "#1088E7",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
            >
              Terms and conditions
            </div>
            <div style={{ padding: 18, overflowX: "auto" }}>
              <div
                style={{
                  width: 854,
                  height: 398,
                  background: "#F6F8F9",
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    font: "normal",
                    letterSpacing: 0.7,
                    color: "#2D3645",
                    opacity: 1,
                    fontSize: 20,
                    fontWeight: 600,
                    paddingLeft: 15,
                    paddingTop: 15,
                  }}
                >
                  ELECTRONIC RECORD AND SIGNATURE DISCLOSURE
                </div>
                <div
                  className="content"
                  style={{
                    textAlign: "left",
                    font: "normal",
                    letterSpacing: 0.35,
                    color: "#2D3645",
                    opacity: 1,
                    fontSize: 14,
                    paddingLeft: 15,
                    paddingTop: 10,
                  }}
                >
                  Please read the following information carefully. By clicking
                  the 'I agree' button, you agree that you have reviewed the
                  followingterms and conditions and consent to transact business
                  electronically using SignPe electronic signature system. If
                  you do notagree to these terms, do not click the 'I agree'
                  button.
                </div>
                <div
                  style={{
                    font: "normal",
                    letterSpacing: 0.7,
                    color: "#2D3645",
                    opacity: 1,
                    fontSize: 20,
                    fontWeight: 600,
                    paddingLeft: 15,
                    paddingTop: 15,
                  }}
                >
                  Electronic documentsc
                </div>
                <div
                  className="content"
                  style={{
                    textAlign: "left",
                    font: "normal",
                    letterSpacing: 0.35,
                    color: "#2D3645",
                    opacity: 1,
                    fontSize: 14,
                    paddingTop: 10,
                    paddingLeft: 15,
                  }}
                >
                  Please note that “SENDER NAME” ("we", "us" or "Company") will
                  send all documents electronically to you to the email address
                  thatyou have given us during the course of the business
                  relationship unless you tell us otherwise in accordance with
                  the procedureexplained herein. Once you sign a document
                  electronically, we will send a PDF version of the document to
                  you.
                </div>
                <div
                  style={{
                    font: "normal",
                    letterSpacing: 0.7,
                    color: "#2D3645",
                    opacity: 1,
                    fontSize: 20,
                    fontWeight: 600,
                    paddingLeft: 15,
                    paddingTop: 15,
                  }}
                >
                  Request for paper copies
                </div>
                <div
                  className="content"
                  style={{
                    textAlign: "left",
                    font: "normal",
                    letterSpacing: 0.35,
                    color: "#2D3645",
                    opacity: 1,
                    fontSize: 14,
                    paddingTop: 10,
                    paddingLeft: 15,
                  }}
                >
                  You have the right to request from us paper copies of the
                  documents that are sent to you electronically. You have the
                  ability todownload and print documents that are sent to you
                  electronically. If you wish to receive paper copies of the
                  documents that wehave sent to you electronically, you can send
                  an email to “ sender@email.com “We will charge 0 per page for
                  sending thedocuments as paper copies.
                </div>
                <div
                  style={{
                    font: "normal",
                    letterSpacing: 0.7,
                    color: "#2D3645",
                    backgroundColor: "#F6F8F9",
                    opacity: 1,
                    fontSize: 20,
                    fontWeight: 600,
                    paddingLeft: 15,
                    paddingTop: 15,
                  }}
                >
                  Withdrawing your consent
                </div>
              </div>
            </div>
            <div
              style={{
                height: 40,
                width: 288,
                position: "relative",
                left: 594,
                top: 23,
              }}
            >
              <button
                style={{
                  font: "normal",
                  letterSpacing: 0.53,
                  position: "relative",
                  right: 30,
                  color: "#2D3645",
                  opacity: 1,
                  background: "#F2F4F7",
                  borderRadius: 5,
                }}
                onClick={() => setisConditionOpen(false)}
              >
                Close
              </button>

              <div
                onClick={() => {
                  setIsTermsConditionAccepted(true);
                  setisConditionOpen(false);
                }}
                style={{
                  textAlign: "center",
                  font: "normal",
                  letterSpacing: 0.75,
                  color: "#FFFFFF",
                  opacity: 1,
                  cursor: "pointer",
                  background: "#1088E7",
                  borderRadius: 5,
                  opacity: 1,
                  height: 40,
                  width: 197,
                  position: "relative",
                  left: 85,
                  bottom: 41,
                  paddingTop: 7,
                }}
              >
                Agree & Continue
              </div>
            </div>
          </div>
        )}
      </Modal> */}
      {documentInfo && !showInfo && !isConditionOpen && (
        <Modal
          isOpen={isDocInfoOpen}
          style={customStyle}
          onRequestClose={() => setisDocInfoOpen(true)}
        >
          <div
            className="info-modal2"
            // style={{
            //   width: 606,
            //   minHeight: 310,
            //   backgroundColor: "#FFFFFF",
            //   borderRadius: 10,
            // }}
          >
            <div className="row" style={{ marginTop: 15 }}>
              <div
                className="col-sm-5"
                style={{
                  fontSize: 18,
                  paddingLeft: 45,
                  color: "#5A6B82",
                }}
              >
                <div
                  className="info-modal"
                  // style={{
                  //   width: 606,
                  //   minHeight: 310,
                  //   backgroundColor: "#FFFFFF",
                  //   borderRadius: 10,
                  // }}
                >
                  <div
                    className="row"
                    style={{
                      textAlign: "left",
                      fontWeight: 600,
                      fontSize: 20,
                      color: "#2D3645",
                      paddingLeft: 45,
                      paddingTop: 20,
                      height: 70,
                    }}
                  >
                    Document info
                  </div>
                  <div className="row">
                    <div
                      className="col-sm-5"
                      style={{
                        fontSize: 18,
                        paddingLeft: 45,
                        color: "#5A6B82",
                      }}
                    >
                      Document name
                    </div>
                    <div
                      className="col-sm-7"
                      style={{
                        fontWeight: 700,
                        // paddingTop: 20,
                        color: "#2D3645",
                        // display: "-webkit-box",
                        // WebkitLineClamp: 2,
                        // WebkitBoxOrient: "vertical",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis"
                      }}
                    >
                      {props.metaData ? props.metaData?.originalname : ""}
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 15 }}>
                    <div
                      className="col-sm-5"
                      style={{
                        fontSize: 18,
                        paddingLeft: 45,
                        color: "#5A6B82",
                      }}
                    >
                      Sender
                    </div>
                    <div
                      className="col-sm-7"
                      style={{
                        fontWeight: 700,
                        color: "#2D3645",
                      }}
                    >
                      {userName}
                      <br></br>
                      {userEmail}
                      {/* {props.user_name ? props.user_name : ''}
            {console.log("props.user_name", props.user_name)} */}
                      <br></br>
                      {/* {props.user_email ? props.user_email : ''} */}
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 15 }}>
                    <div
                      className="col-sm-5"
                      style={{
                        fontSize: 18,
                        paddingLeft: 45,
                        color: "#5A6B82",
                      }}
                    >
                      Sent on
                    </div>
                    <div
                      className="col-sm-7"
                      style={{
                        fontWeight: 700,
                        color: "#2D3645",
                      }}
                    >
                      {props.metaData
                        ? moment(props.metaData?.createdAt).format(
                            "DD-MMM-YYYY hh:mm A"
                          )
                        : ""}
                      {/* <Expires in 13 days> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginRight: "70px",
                    }}
                  >
                    <button
                      onClick={() => setisDocInfoOpen(false)}
                      style={{
                        backgroundColor: "#F2F4F7",
                        height: 36,
                        width: 86,
                        // marginLeft: 435,
                        color: "#000000",
                        textAlign: "center",
                        borderRadius: 5,
                        marginTop: 45,
                        marginBottom: 25,
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        isOpen={isSignSaveOpen}
        style={completeModalStyles}
        onRequestClose={() => setisSignSaveOpen(true)}
      >
        <div
          className=" email-modal box"
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            paddingTop: 330,
          }}
        >
          <div
            style={{
              fontSize: 25,
              fontWeight: 500,
              color: "#2D3645",
              marginRight: 35,
            }}
          >
            You have signed this document.
          </div>
          {!isDownloading ? (
            <button
              onClick={(e) => {
                doDownloadFile(e);
              }}
              //src={saveDownload_Icon}
              width="20"
              height="20"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#1088E7",
                height: 48,
                width: 145,
                borderRadius: 4,
                fontSize: 18,
                marginRight: 40,
                marginTop: 35,
              }}
            >
              <div style={{ display: "flex", paddingLeft: 30 }}>
                <div>Download</div>
                <div
                  style={{
                    paddingLeft: 22,
                    paddingTop: 4,
                  }}
                >
                  {/* <img
                    src={saveDownload_Icon}
                    style={{
                      width: 18,
                      height: 18
                    }} /> */}
                </div>
              </div>
            </button>
          ) : (
            <button
              style={{
                opacity: 0.5,
                backgroundColor: "#1088E7",
                height: 48,
                width: 145,
                borderRadius: 4,
                color: "#FFFFFF",
                fontSize: 18,
                marginRight: 40,
                marginTop: 35,
                cursor: "not-allowed",
              }}
            >
              <i class="fa fa-circle-o-notch fa-spin"></i>
            </button>
          )}
          {/* <a href="mailto:?subject=SignPe&body=" target="_blank"> */}
          <button
            onClick={(e) => {
              EmailSent(e);
            }}
            src={saveDownload_Icon}
            width="20"
            height="20"
            style={{
              color: "#FFFFFF",
              backgroundColor: "#1088E7",
              height: 48,
              width: 164,
              borderRadius: 4,
              fontSize: 18,
              marginRight: 40,
              marginTop: 35,
            }}
          >
            <div style={{ display: "flex", paddingLeft: 35 }}>
              <div>Email to me</div>
              <div
                style={{
                  paddingLeft: 22,
                  paddingTop: 4,
                }}
              >
                {/* <img
                  src={saveEmail_icon}
                  style={{
                    width: 23,
                    height: 18
                  }} /> */}
              </div>
            </div>
          </button>
          {/* </a> */}
          <div
            style={{
              fontSize: 18,
              color: "#2D3645",
              paddingTop: 40,
              cursor: "pointer",
              paddingRight: 50,
            }}
            onClick={() => window.location.reload()}
          >
            <a style={{ textDecoration: "underline" }}>Go to SignPe home</a>
          </div>
        </div>
        {/* </div> */}
      </Modal>
      {!isConditionOpen && (
        <Modal
          isOpen={isMailOpen}
          style={customStyle}
          onRequestClose={() => setisMailOpen(true)}
        >
          <form onSubmit={click}>
            <div
              className=" email-modal box"
              style={{
                width: 600,
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
              }}
            >
              <div className="">
                <div
                  className=""
                  style={{
                    textalign: "left",
                    height: 65,
                    color: "#FFFFFF",
                    paddingLeft: 30,
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "flex-start",
                    background: "#1088E7",
                    fontSize: 18,
                    fontWeight: 400,
                    padding: "10px 45px",
                  }}
                >
                  Email Document
                </div>
              </div>
              <div style={{ padding: "5px 45px", marginTop: 30 }}>
                Recipient added here will get a copy of the document.
              </div>
              <div style={{ padding: "0px 45px" }}>
                {user}
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  disabled={isLoader ? true : false}
                  style={{
                    paddingRight: "39px !important",
                    display: "flex",
                    textAlign: "inherit",
                    justifyContent: "center",
                    cursor: isLoader ? "not-allowed" : "default",
                  }}
                  id="email-address"
                  name="email"
                  className="appearance-none rounded-none relative block w-full px-3 py-2 pr-5 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                    if (e.target.value.trim() == "") {
                      setEmailError(
                        <span style={{ color: "red", fontSize: "14px" }}>
                          Email cannot be empty
                        </span>
                      );
                    } else if (
                      !e.target.value.match(
                        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                      )
                    ) {
                      setEmailError(
                        <span style={{ color: "red", fontSize: "14px" }}>
                          Please enter valid email address{" "}
                        </span>
                      );
                    } else {
                      setEmailError();
                      // setMessage("Email Sent");
                      // setShowAlert(true)
                    }
                    setUserError();
                  }}
                />
                {emailError}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  textAlign: "center",
                  padding: "20px 45px",
                }}
              >
                <button
                  style={{
                    font: "normal",
                    letterSpacing: 0.53,
                    height: 40,
                    position: "relative",
                    right: 30,
                    width: 92,
                    color: "#2D3645",
                    opacity: 1,
                    background: "#F2F4F7",
                    borderRadius: 5,
                    // cursor: "inherit"
                  }}
                  onClick={() => setisMailOpen(false)}
                >
                  Close
                </button>
                <div>
                  {!isLoader ? (
                    <button
                      onClick={
                        email == "" || emailError ? "" : sendEmailApiData
                      }
                      style={{
                        backgroundColor: "#378cf2",
                        height: 40,
                        width: 140,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        cursor:
                          emailError || email == "" ? "not-allowed" : "pointer",
                      }}
                      type="click"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 save-close"
                    >
                      {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span> */}
                      Send & Close
                    </button>
                  ) : (
                    <button
                      style={{
                        opacity: 0.5,
                        // backgroundColor: "#378cf2",
                        backgroundColor: "#1088E7",
                        height: 40,
                        width: 140,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                        cursor: "not-allowed",
                        color: "#FFFFFF",
                      }}
                    >
                      <i class="fa fa-circle-o-notch fa-spin"></i>
                    </button>
                  )}
                  {console.log("emailError", emailError)}
                  {console.log('email == ""', email == "")}
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default FromUrl;
