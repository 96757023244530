import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';



const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export const Error = (props) => {
    const [show, setShow] = useState(true);
    // console.log(props);
    return (
        props && props.show ? <Snackbar
            anchorOrigin={{
                vertical: props.position.vertical,
                horizontal: props.position.horizontal,
            }}
            open={show}
            autoHideDuration={4000}
            onClose={props.onClose}
        >
            <Alert onClose={() => setShow(false)} severity={props.status || "error"}>
                {typeof props.message === 'string' ? props.message : props.message.message}
            </Alert>
        </Snackbar> : null
    )
}

// Error.defaultProps = {
//     position: {
//         vertical: 'top',
//         horizontal: 'right'
//     }
// }
// Error.propTypes = {
//     props.show: React.PropTypes.bool.isRequired,
//     message: React.PropTypes.string.isRequired,
//     duration: React.PropTypes.number.isRequired,
//     position: React.PropTypes.object
// }
