import React, { useEffect, useState } from "react";
import IconTint from "react-icon-tint";

import circle from "../../images/round.svg";
import roundedRectangle from "../../images/round-square.svg";
import triangle from "../../images/triangle.svg";
import diamond from "../../images/angular.svg";
import pentagon from "../../images/polygon.svg";
import hexagon from "../../images/polygon2.svg";
import octagon from "../../images/polygon3.svg";
import decagon from "../../images/polygon4.svg";
import doDecagon from "../../images/polygon5.svg";
import heart from "../../images/heart.svg";
import star5 from "../../images/star1.svg";
import star6 from "../../images/star2.svg";
import star7 from "../../images/star3.svg";
import star8 from "../../images/star4.svg";
import square from "../../images/square.svg";
import curve_Ractangle from "../../images/round_rectangle.png";
import square_Ractangle from "../../images/simple_rectangle.png";
import { isMobile } from "react-device-detect";
const xDiff = 40;
const yDiff = 40;
const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
const Annotation = (props) => {
  const [showImage, setShowImage] = useState(true);
  const [imageData, setImageData] = useState("");
  const reducePxTop =
    window.innerWidth < 750
      ? 5
      : 0 || window.innerWidth < 950
      ? 12
      : 0 || window.innerWidth < 1150
      ? 24
      : 0;
  const reducePxLeft =
    window.innerWidth < 750
      ? 5
      : 0 || window.innerWidth < 950
      ? 18
      : 0 || window.innerWidth < 1150
      ? 18
      : 0;

  console.log(props.cursor);
  // console.log(props.data.assignedTo );
  console.log(props.email);
  // console.log(props.data);
  const convert = (data) => {
    console.log(data, "data");
    // <img src={`data:image/png;base64,${data}`} />
    // debugger
    // document.getElementById('container')
    // ReactDOM.render(<Example data={data} />, document.getElementById('container'))
    // debugger
    fetch(data)
      .then((text) => text.text())
      .then((text) => {
        const index = text.indexOf("stroke");
        const semiColonIndex = text.indexOf(";", index);
        const toChange = text.substring(index, semiColonIndex + 1);
        // debugger
        // console.log('String to change is --> ', toChange);
        // console.log('Color is ', props.data.color, props.data.color.length);
        // const newValue = `stroke: #${ props.data.color.length === 6 ? props.data.color.substr(1) : props.data.color.substr(3) };`
        const newValue = `stroke: #${
          props.data.color.length === 7
            ? props.data.color.substr(1)
            : props.data.color.substr(3)
        };`;
        // const rect_shape= (curve_Ractangle || square_Ractangle)
        // console.log('new value is --> ', props.data.color.substr)
        console.log("props.data.color.substr(1)", props.data.color.substr(3));

        text = text.replace(toChange, newValue);
        // console.log('new String is ', text)
        let blob = new Blob([text], { type: "image/svg+xml" });
        const url = URL.createObjectURL(blob);
        // debugger
        setImageData(url);
      });
  };

  const getSource = () => {
    if (props.data.shape.includes("ShapeType.Circle")) {
      return convert(circle);
    } else if (props.data.shape.includes("ShapeType.Rect")) {
      return convert(square);
      // debugger
    } else if (props.data.shape.includes("ShapeType.RoundedRect")) {
      return convert(roundedRectangle);
      // debugger
    } else if (props.data.shape.includes("ShapeType.Triangle")) {
      return convert(triangle);
    } else if (props.data.shape.includes("ShapeType.Diamond")) {
      return convert(diamond);
    } else if (props.data.shape.includes("ShapeType.Pentagon")) {
      return convert(pentagon);
    } else if (props.data.shape.includes("ShapeType.Hexagon")) {
      return convert(hexagon);
    } else if (props.data.shape.includes("ShapeType.Octagon")) {
      return convert(octagon);
    } else if (props.data.shape.includes("ShapeType.curve_Ractangle")) {
      return convert(curve_Ractangle);
    } else if (props.data.shape.includes("ShapeType.square_Ractangle")) {
      return convert(square_Ractangle);
    } else if (props.data.shape.includes("ShapeType.Decagon")) {
      return convert(decagon);
    } else if (props.data.shape.includes("ShapeType.Dodecagon")) {
      return convert(doDecagon);
    } else if (props.data.shape.includes("ShapeType.Heart")) {
      return convert(heart);
    } else if (props.data.shape.includes("Star5")) {
      return convert(star5);
    } else if (props.data.shape.includes("Star6")) {
      return convert(star6);
    } else if (props.data.shape.includes("Star7")) {
      return convert(star7);
    } else if (props.data.shape.includes("Star8")) {
      return convert(star8);
    } else if (props.data.shape.includes("Diamond")) {
      return convert(diamond);
    }
  };

  useEffect(() => {
    if (props.data !== null && props.data.shape) {
      getSource();
    }
  }, [props]);

  const getSources = () => {
    const getHeight =
      (((props.renderingHeight * props.pageWidth) / props.renderingWidth) *
        props.data.height) /
      props.renderingHeight;
    const getWidth =
      (props.data.width * props.pageWidth) / props.renderingWidth;
    // debugger
    console.log("props.data.type", props.data.type);
    console.log("square", square);
    const test =
      (props.data.shape.includes("ShapeType.Rect") ||
        props.data.shape.includes("ShapeType.RoundedRect")) &&
      getWidth > getHeight
        ? props.data.shape.includes("ShapeType.Rect")
          ? square_Ractangle
          : curve_Ractangle
        : imageData;
    // debugger
    return test;
  };
  // debugger
  return props && (props.data.imageBase64 || props.data.type === "Shape") ? (
    <div
      style={{
        ...props.style,
        left:
          (props.data.x * (props.pageWidth / props.renderingWidth) - xDiff) *
            props.scale -
          reducePxLeft,
        // top: props.data.y * (props.pageHeight / 631.3)
        top:
          ((((props.renderingHeight * props.pageWidth) / props.renderingWidth) *
            props.data.y) /
            props.renderingHeight -
            yDiff) *
            props.scale -
          reducePxTop,
        width: props.data.width * (props.pageWidth / props.renderingWidth) - 12,
        height:
          props.data.height * (props.pageHeight / props.renderingHeight) - 12,
        border:
          props.data.shape == "ShapeType.RoundedRect" ||
          props.data.shape == "ShapeType.Rect"
            ? "3px solid black"
            : "none",
        borderRadius: props.data.shape == "ShapeType.RoundedRect" && "10px",
      }}
      // style={{
      //   ...props.style,
      //   left: (props.data.x * (props.pageWidth / props.renderingWidth) - xDiff),
      //   // top: props.data.y * (props.pageHeight / 631.3)
      //   top:
      //     ((((props.renderingHeight * props.pageWidth) / props.renderingWidth) *
      //       props.data.y) /
      //       props.renderingHeight -
      //       yDiff),
      // }}
    >
      {/* {props.data.shape.includes("ShapeType.Rect") || props.data.shape.includes("ShapeType.RoundedRect") ? 
        <IconTint
        id="img"
        alt="Signature"
        width={(props.data.width * props.pageWidth) / props.renderingWidth}
        // height={(props.data.height * props.pageHeight) / props.renderingHeight}
        height={
          (((props.renderingHeight * props.pageWidth) / props.renderingWidth) *
            props.data.height) /
          props.renderingHeight
        }
        onClick={() => {
          // console.log(isDragging, showImage);
          if (
            props.status !== "SENT" &&
            props.data.assignedTo === props.email
          ) {
            if (showImage) {
              props.showCanvas(props.index);
            } else {
              setShowImage(true);
            }
          }
        }}
        style={{
          width: props.data.width * (props.pageWidth / props.renderingWidth),
          height:
            props.data.height * (props.pageHeight / props.renderingHeight),
          objectFit: props.data.type === "Shape" ? "unset" : "contain",
          IconTint: "blue"
          // imageRendering: "pixelated"
        }}
        color=
        {props.data.type === "Text"
          ? `#${props.data.color.length === 7
            ? props.data.color.substr(1)
            : props.data.color.substr(3)
          }`
          : "red"}
        
        // debugger
        src={
          // props.data.type === "Shape" ? imageData : props.data.imageBase64
          props.data.shape.includes("ShapeType.Rect") ?  square_Ractangle :curve_Ractangle
        }
        // debugger
      /> : */}
      <img
        id="img"
        alt="Signature"
        width={(props.data.width * props.pageWidth) / props.renderingWidth}
        // height={(props.data.height * props.pageHeight) / props.renderingHeight}
        height={
          (((props.renderingHeight * props.pageWidth) / props.renderingWidth) *
            props.data.height) /
          props.renderingHeight
        }
        onClick={() => {
          // console.log(isDragging, showImage);
          if (
            props.status !== "SENT" &&
            props.data.assignedTo === props.email
          ) {
            if (showImage) {
              props.showCanvas(props.index);
            } else {
              setShowImage(true);
            }
          }
        }}
        style={{
          width: props.data.width * (props.pageWidth / props.renderingWidth),
          height:
            props.data.height * (props.pageHeight / props.renderingHeight),
          objectFit: props.data.type === "Shape" ? "unset" : "contain",
          display:
            (props.data.shape == "ShapeType.RoundedRect" ||
              props.data.shape == "ShapeType.Rect") &&
            "none",
          // IconTint: "blue",
          // imageRendering: "pixelated"
        }}
        src={
          props.data.type === "Shape" ? imageData : props.data.imageBase64
          // curve_Ractangle
          //  props.data.imageBase64.substr(0, 4) === "data"
          // 	? props.data.imageBase64
          // 	: `data:image/png;base64,${props.data.imageBase64}`
          // props.data.type != "Shape" ?  props.data.imageBase64 :getSources()
        }
      />
      {/* } */}
    </div>
  ) : (
    <>
      {props.renderingHeight > 0 &&
        props.pageWidth > 0 &&
        props.renderingWidth > 0 && (
          <div
            style={{
              ...props.style,
              left:
                props.data.x * (props.pageWidth / props.renderingWidth) -
                xDiff -
                reducePxLeft,
              top:
                (((props.renderingHeight * props.pageWidth) /
                  props.renderingWidth) *
                  props.data.y) /
                  props.renderingHeight -
                yDiff -
                reducePxTop,
            }}
          >
            <button
              disabled={
                props.data.assignedTo !== props.email ||
                props.isVoid === true ||
                props.isArchived === true ||
                props.isDeclined === true ||
                props.cursor === false
              }
              className={props.data.type === "Signature" ? "pl-2 pr-2" : ""}
              onClick={() => {
                if (props.status !== "SENT") {
                  if (showImage) {
                    props.showCanvas(props.index);
                  } else {
                    setShowImage(true);
                  }
                }
              }}
              style={{
                cursor:
                  props.data.assignedTo !== props.email ||
                  props.isVoid === true ||
                  props.isArchived === true ||
                  props.isDeclined === true ||
                  props.cursor === false
                    ? "not-allowed"
                    : "pointer",
                width:
                  props.data.type === "Text"
                    ? "100%"
                    : (props.data.width * props.pageWidth) /
                      props.renderingWidth,

                height:
                  props.data.type === "Text"
                    ? "100%"
                    : (((props.renderingHeight * props.pageWidth) /
                        props.renderingWidth) *
                        props.data.height) /
                      props.renderingHeight,

                backgroundColor:
                  props.data.type === "Signature"
                    ? "rgb(255 241 222)"
                    : // ? props.data.bgColor
                    props.data.type === "Text"
                    ? "transparent"
                    : "rgba(0,255,0,0.2)",

                fontSize:
                  props.data.type === "Text"
                    ? props.data.fontSize
                      ? props.data.fontSize
                      : ""
                    : "",

                color:
                  props.data.type === "Text"
                    ? `#${
                        props.data.color.length === 7
                          ? props.data.color.substr(1)
                          : props.data.color.substr(3)
                      }`
                    : "",
                wordWrap: "break-word",
                display: "table-cell",
                border:
                  props.data.type === "Signature" &&
                  props.data.assignedTo === props.email
                    ? "1px dashed"
                    : "",
              }}
            >
              {console.log(props.data.imageBase64, "kkkkkkkkk")}
              {/* <div> */}
              {props.data.type === "Signature" ? (
                `${props.data.assigneeName}'s\nSign`
              ) : (
                <div
                  align="left"
                  style={{ whiteSpace: "pre-line" }}
                  // dangerouslySetInnerHTML={{ __html:  }}
                  // dangerouslySetInnerHTML={{ __html: `<p>a <br><br><br><br><br><br><br> s</p>` }}
                >
                  {props.data.text}
                </div>
              )}
              {/* </div> */}
            </button>
          </div>
        )}
    </>
  );
};

export default Annotation;
