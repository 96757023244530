import { useState } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../helpers/constants";
import Modal from "react-modal";
import logoImage from "../../images/signlogo.png"; 
import newLogoImage from "../../images/updated_signlogo.png"; 


const Header = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [button, setButton] = useState(false);
 


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "400px",
      height: "185px",
      backgroundColor: "#E9E9E9",
      // textAlign: "center",
      // padding: "55px"
    },
    overlay: {
      background: "rgba(0,0,0,0.7)",
    },

  };

  return (

    <>
      <div style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px" }}>
        <div className="antialiased bg-gray-100 dark-mode:bg-gray-900">
          <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
            <div x-data={() => setButton(false)} className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
              {/* <div className="p-4 flex flex-row items-center justify-between col-lg-4 col-md-4 col-sm-12 col-xs-12  "> */}
                <img
                className="mx-auto h-12"
                style={{ height: 30,paddingRight: 140 }}
                src={newLogoImage}
                alt="Workflow"
              />
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  href={`${baseUrl}/login`}
                  className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none "
                >
              </a>
                <div className=" xl-1 text-md text-gray-900 font-weight-bold  ">
                  {props.text}
                </div>
                <button className="md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => { setButton(!button) }} >
                  <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                    {button && (<path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h 12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd" />)}
                    {!button && (<path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />)}
                  </svg>
                </button>
              {/* </div> */}

              <nav className={`flex-col flex-grow pb-4 md:pb-0  md:flex md:justify-end md:flex-row  ${!button ? "flex" : "hidden"} `} style={{ marginBottom: "-18px" }}>
                <a
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
                  href={"/contact"}
                >
                  Contact
              </a>
                {props.showLogout === true ? (
                  <a
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
                    onClick={() => {
                      window.localStorage.clear();
                      history.push("/login");
                    }}
                    onClick={() => setShow(true)}
                  >
                    Logout
                  </a>
                ) : null}


                {/* <div className="relative" x-data={() => setDropdown(false)}>
                  <button onClick={() => { setDropdown(!dropdown) }} className="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                    <span>Dropdown</span>
                    <svg fill="currentColor" viewBox="0 0 20 20" className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 ${dropdown ? 'rotate-180' : 'rotate-0'}`}>      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                  </button>
                  <div x-show="open" className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg md:w-48">
                    <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                      <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #1</a>
                      <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #2</a>
                      <a className="block px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#">Link #3</a>
                    </div>
                  </div>
                </div> */}
              </nav>

              <div>
                <Modal
                  style={customStyles}
                  isOpen={show}
                  onRequestClose={() => {
                    setShow(false);
                  }}
                >
                  <div style={{ alignContent: "left", fontWeight: "500", marginBottom: "5px", fontSize: "23px" }}>Logout Alert</div>
                Are you sure you want to logout?
                <div style={{ marginLeft: "225px", paddingTop: "55px" }}>
                    <span
                      style={{
                        cursor: "pointer",
                        textAlign: "right",
                        border: "0px",
                        width: "80px",
                        backgroundColor: "transparent",
                        color: "#2b2b2b"
                      }}
                      onClick={() => {
                        setShow(false)
                      }}>Cancel</span>
                    <span
                      style={{
                        cursor: "pointer",
                        marginLeft: "30px",
                        textAlign: "right",
                        border: "0px",
                        width: "80px",
                        backgroundColor: "transparent",
                        color: "#007bff"
                      }}
                      onClick={() => {
                        window.localStorage.clear();
                        history.push("/login");
                      }}>Logout</span>

                  </div>
                </Modal>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};




Header.defaultProps = {
  text: "",
  showLogout: false,
};

export default Header;


// <div style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 10px" }}>
//   <div className="antialiased bg-gray-100 dark-mode:bg-gray-900">
//     <div className="w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
//       {/* <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8"> */}
//       <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
//         <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 xl-1 flex-row items-center justify-between p-4">
//           {/* <div className="col-4 flex-row items-center justify-between p-4"> */}
//           <a
//             style={{ cursor: "pointer", textDecoration: "none" }}
//             href={`${baseUrl}/login`}
//             className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none "
//           >
//             Sign App
//               </a>
//         </div>
//         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 xl-1 text-md text-gray-900 font-weight-bold">
//           {/* <div className="col-4 text-md text-gray-900 font-weight-bold"> */}
//           {props.text}
//         </div>
//         {/* <nav > */}
//         <nav className="col-lg-3 col-md-3 col-sm-12 col-xs-12 xl-1">
//           {/* <a
//                 style={{ cursor: "pointer", textDecoration: "none" }}
//                 className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
//                 href="#"
//               >
//                 About
//               </a> */}
//           <a
//             style={{ cursor: "pointer", textDecoration: "none" }}
//             className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
//             href={"/contact"}
//           >
//             Contact
//               </a>
//           {props.showLogout === true ? (
//             <a
//               style={{ cursor: "pointer", textDecoration: "none" }}
//               className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none "
//               onClick={() => {
//                 window.localStorage.clear();
//                 history.push("/login");
//               }}
//               onClick={() => setShow(true)}
//             >
//               Logout
//             </a>
//           ) : null}
//         </nav>

//         <div>
//           <Modal
//             style={customStyles}
//             isOpen={show}
//             onRequestClose={() => {
//               setShow(false);
//             }}
//           >
//             <div style={{ alignContent: "left", fontWeight: "500", marginBottom: "5px", fontSize: "23px" }}>Logout Alert</div>
//                 Are you sure you want to logout?
//                 <div style={{ marginLeft: "225px", paddingTop: "55px" }}>
//               <span
//                 style={{
//                   cursor: "pointer",
//                   textAlign: "right",
//                   border: "0px",
//                   width: "80px",
//                   backgroundColor: "transparent",
//                   color: "#2b2b2b"
//                 }}
//                 onClick={() => {
//                   setShow(false)
//                 }}>Cancel</span>
//               <span
//                 style={{
//                   cursor: "pointer",
//                   marginLeft: "30px",
//                   textAlign: "right",
//                   border: "0px",
//                   width: "80px",
//                   backgroundColor: "transparent",
//                   color: "#007bff"
//                 }}
//                 onClick={() => {
//                   window.localStorage.clear();
//                   history.push("/login");
//                 }}>Logout</span>

//             </div>
//           </Modal>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>







