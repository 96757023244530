import React, { useEffect, useState } from "react";
import { forgotPassword } from "../../services/loginService";
import { Error } from "../Error/error";
import Loader from "../Loader/loader";
import Modal from "react-modal";
import Locks from "../../images/locks.png";
import loginImage from "../../images/login.png";
import newSignAppLogo from "../../images/sign vector_1.png"
import Footer from "../../components/Footer/Footer.jsx";

const loginModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 60,
    padding: 0,
    backgroundColor: "#E9E9E9",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

export const PasswordResetPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState();
  const [message, setMessage] = useState();
  const [user, setUserError] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("Hello");
  }, []);

  // const handleEmailInput = (e) => {
  //   setEmail(e.target.value.toLowerCase());
  // };

  const Header = () => {
    return (
      <header
        className=" mb-0  bg-white"
        style={{
          background: "white",
          color: "black",
          padding: "25px",
          borderBottom: "1px solid #c7c7c7bd",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img
              src={newSignAppLogo}
              style={{
                width: "41px",
                // borderRadius: "30%",
                marginRight: "10px",
              }}
            />
          </div>
          <h1
            style={{
              fontSize: "25px",
              fontWeight: "bolder",
              position: "relative",
              top: "1px",
            }}
          >
            Sign Documents: SignPe
          </h1>
        </div>
      </header>
    );
  };

  // const Footer = () => {
  //   return (
  //     <footer style={{
  //       background: "white",
  //       color: "black",
  //       padding: "25px",
  //       // position: "fixed",
  //       left: "0",
  //       bottom: "0",
  //       width: "100%",
  //       borderTop:'1px solid rgb(181 181 181)'
  //     }}>
  //       <p>Powered by <b>SignPe</b></p>
  //       <div style={{display:"flex"}}>
  //        <p style={{paddingRight:"10px"}}> Contact Us</p>
  //        <p style={{paddingRight:"10px"}} > Terms of Us</p>
  //        <p>Privacy</p>
  //       </div>

  //       <p>Copyright &copy; SignPe. All rights reserved.</p>
  //     </footer>
  //   );
  // };

  const handleClose = () => {
    setError(false);
  };

  //   const openPasswordForgotPage = () => {
  //     window.open("https://signapp-web.netlify.app/reset-password");
  //   };

  // const submit = async () => {
  async function submit(e) {
    e.preventDefault();
    try {
      if (email == "") {
        setEmailError(
          <span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>
            Email cannot be empty
          </span>
        );
      } else {
        setIsLoading(true);
        const response = await forgotPassword(email);
        setIsLoading(false);
        setSuccess(true);
        //   window.close();
        //   history.push("/dashboard");
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      // setMessage("Please enter email");
      // setError(true);
      console.log(e);
      if (e.message) {
        setUserError(
          <span style={{ color: "red", fontSize: "15px", paddingLeft: "3px" }}>
            No such email exists
          </span>
        );
      } else {
        setUserError();
      }
    }
  }

  return !success ? (
    <div className="min-h-screen flex flex-col items-center justify-center sm:py-12">
      <div className="p-10 xs:p-0 mx-auto xl:w-full xl:max-w-xl">
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200 p-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={newSignAppLogo}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Reset Password
            </h2>
          </div>
          <form onSubmit={submit}>
            {/* <input type="hidden" name="remember" defaultValue="true" /> */}
            <div className="mt-8 space-y-6">
              <div>
                {user}
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                    onChange={(e) => {
                      setEmail(e.target.value.toLowerCase());
                      if (e.target.value.trim() == "") {
                        setEmailError(
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingLeft: "3px",
                            }}
                          >
                            Email cannot be empty
                          </span>
                        );
                      } else if (
                        !e.target.value.match(
                          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                        )
                      ) {
                        setEmailError(
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              paddingLeft: "3px",
                            }}
                          >
                            Please enter valid email address
                          </span>
                        );
                      } else {
                        setEmailError();
                      }
                      setUserError();
                    }}
                  />
                  {emailError}
                </div>
              </div>
              <div>
                <button
                  style={{ backgroundColor: "#378cf2" }}
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={submit}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    {/* <svg
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg> */}
                    <img src={Locks} height="18px" width="18px"></img>
                  </span>
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        isOpen={isLoading}
        style={loginModalStyle}
        onRequestClose={() => {
          setIsLoading(false);
        }}
      >
        <Loader />
      </Modal>
      {/* <Error
        show={error}
        message={message}
        duration={4000}
        position={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      /> */}
    </div>
  ) : (
    <>
      <Header />
      <div
        style={{
          height: "calc(100vh - 215px)",
          background: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {/* change style up  style={{ position: "absolute", marginLeft: "42%" }} */}
          <link
            href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,900&display=swap"
            rel="stylesheet"
          />
          {/* <style
        dangerouslySetInnerHTML={{
          __html:
               body { text-align: center; padding: 40px 0; background: #F0F0F0;}
                h1 {  color: #88B04B; font-family: "Nunito Sans", "Helvetica Neue", sans-serif; font-weight: 900; font-size: 40px;
            margin-bottom: 10px;\n    }\n\n    p {\n        color: #404F5E;\n        font-family: "Nunito Sans", "Helvetica Neue", sans-serif;\n    
            font-size: 20px;\n        margin: 0;\n    }
            i { color: #9ABC66; font-size: 100px; line-height: 20px; margin-left: -15px;\n    }
            .card {\n        background: white;\n        padding: 60px;\n        border-radius: 4px;       
            box-shadow: 0 2px 3px #C8D0D8;\n        display: inline-block;\n        margin: 0 auto;\n    }',
        }}
      /> */}
          <div style={{}}>
            <svg
              style={{ margin: "auto", display: "block" }}
              xmlns="http://www.w3.org/2000/svg"
              width={100}
              height={100}
              viewBox="0 0 100 100"
              fill="none"
            >
              <circle cx={50} cy={50} r={50} fill="#26B47F" />
              <path
                d="M43.875 5.37327C43.875 2.54484 41.6154 0.25 38.8258 0.25C37.2459 0.25 35.837 0.987934 34.9103 2.14009L34.9084 2.13816L16.7115 24.8158L8.88268 16.2132L8.87889 16.2171C7.95606 15.2036 6.64012 14.5659 5.17421 14.5659C2.38477 14.5639 0.125 16.8567 0.125 19.6872C0.125 21.0282 0.637698 22.2439 1.46749 23.1572L1.46369 23.161L13.2421 36.1026L13.2459 36.0988C14.1687 37.1122 15.4847 37.75 16.9506 37.75C18.5304 37.75 19.9394 37.0121 20.866 35.8599L20.8679 35.8618L42.7428 8.60646L42.7409 8.60453C43.4492 7.724 43.8745 6.59882 43.8745 5.37342L43.875 5.37327Z"
                fill="white"
                transform="translate(28, 32)"
              />
            </svg>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              padding: "0px 30px",
            }}
          >
            <p>Reset Password link has been sent to your register email</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
