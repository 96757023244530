import React from "react";

export const DocumentStatus = (props) => {
  // Add text-xs for regular text
  return (
    <div className="container" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <table className="text-left w-full">
        <thead className="bg-gray-50 flex w-full">
          <tr className="flex mb-2 w-full">
            <th
              scope="col"
              className="pl-12 w-1/2 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
            >
              Title
            </th>

            <th
              scope="col"
              className="pl-32 pr-16 w-1/2 text-center text-xs font-bold text-gray-700 uppercase tracking-wider"
            >
              Status
            </th>
          </tr>
        </thead>

        <tbody
          className="bg-white flex flex-col divide-y divide-gray-200 items-left justify-left overflow-y-auto w-full"
          style={{ height: 500, width: 500 }}
        >
          {props.data.map((item) => {
            return (
              <tr className="flex w-full mb-4">
                <td className="pl-12 py-4 pr-12 w-1/2 text-left text-sm text-gray-500">
                  <div className="text-left">
                    <div className="text-sm font-medium text-gray-900">
                      {item.user_name}
                    </div>
                  </div>
                </td>

                <td className="pl-12 py-4 pr-12 w-1/2 text-left text-sm text-gray-500">
                  <div className="pl-20 text-left text-sm font-weight-bold text-green-800">
                    {item.role === "CREATOR" ? "CREATED" : item.document_status}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center" style={{ marginTop: 20 }}>
        <button className="btn btn-primary" onClick={props.close}>
          Done
        </button>
      </div>
    </div>
  );
};
