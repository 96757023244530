import { useEffect, useRef, useState } from "react";
// import SignaturePad from "signature_pad";
import SignaturePad from "react-signature-canvas";
import { SketchPicker } from "react-color";
import "./canvas.css";
import { blue, lightBlue, pink, red } from "@material-ui/core/colors";
import gallery_icon from "../../images/gallery_icon.png";
import { Box } from "@material-ui/core";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const Canvas = (props) => {
  let signaturePad = useRef();
  const colorStoke = ["#F44336", "#2196F3", "#000000"];
  const [strokeStyle, setStrokeStyle] = useState(colorStoke[2]);

  const [showPicker, setShowPicker] = useState(false);
  const [didSign, setDidSign] = useState(false);

  function onBegin() {
    setDidSign(true);
  }

  const erase = () => {
    setDidSign(false);
    signaturePad.current.clear();
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadedImageSelect = () => {
    setDidSign(true);
    const file = document.getElementById("uploadedImage");
    toBase64(file.files[0]).then((value) => {
      console.log(value, "inth");
      props.onSave(value, true);
    });
  };

  function save() {
    console.log(signaturePad.current);
    signaturePad.current.background = "transparent";
    const data = signaturePad.current.getTrimmedCanvas().toDataURL("image/png");
    props.onSave(data, didSign);
  }

  const onColorChange = (color) => {
    const canvas = signaturePad.current.getCanvas();
    const pic = new Image();
    pic.src = canvas.toDataURL();
    const tintCanvas = document.createElement("canvas");
    const tintCtx = tintCanvas.getContext("2d");
    const ctx = canvas.getContext("2d");

    // var context = canvas.getContext('2d');
    // context.save();
    // context.fillStyle = color;
    // context.globalCompositeOperation = 'source-in';
    // context.fillRect(0,0,canvas.width, canvas.height);
    // context.restore();
    // canvas.setContext(context)

    pic.onload = () => {
      tintCanvas.width = canvas.width;
      tintCanvas.height = canvas.height;
      // tintCtx.fillStyle = color.hex;
      tintCtx.fillStyle = color;
      tintCtx.fillRect(0, 0, canvas.width, canvas.height);
      tintCtx.globalCompositeOperation = "destination-atop";
      tintCtx.drawImage(pic, 0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = 1;
      ctx.drawImage(tintCanvas, 0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.restore();
    };
    // setStrokeStyle(color.hex);
    setStrokeStyle(color);
  };

  return (
    <div className="col-lg-12" style={{ marginTop: 20 }}>
      <div
        className="row"
        style={{
          borderStyle: "solid 1px",
          // height: "25vh"
        }}
      >
        {/* <div className="col-1"></div> */}
        <div className="col-12" style={{ height: isMobile ? "25vh" : 250 }}>
          <div
            className={"sigContainer"}
            style={{
              marginTop: -4,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <SignaturePad
              minWidth={2.5}
              canvasProps={{ className: "sigPad" }}
              penColor={strokeStyle}
              onBegin={onBegin}
              ref={signaturePad}
              // ref={(ref) => {
              //     signaturePad = ref;
              // }}
            />
          </div>
          {/* <div className="col-1"></div> */}
        </div>
      </div>

      <div>
        {showPicker ? (
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-3" onClick={() => setShowPicker(false)}></div>
            {/* <div className="col-1" onClick={() => setShowPicker(false)}></div> */}
            <div style={{ marginTop: 12 }} className="col-6 text-right">
              <div>
                {showPicker ? (
                  <div>
                    <div />
                    <SketchPicker
                      color={strokeStyle}
                      disableAlpha={true}
                      onChange={(color) => {
                        setStrokeStyle(color.hex);
                      }}
                      // onChangeComplete={(color) => {
                      //     setStrokeStyle(color.hex);
                      //     // signaturePad.current.clear()
                      //     signaturePad.current.clear()
                      // }}
                      onChangeComplete={onColorChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-3" onClick={() => setShowPicker(false)}></div>
          </div>
        ) : null}
      </div>

      <br />

      <div className="row" style={{ fontSize: isMobile ? 20 : 14 }}>
        <div className=" text-center ">
          <label
            style={{
              backgroundColor: "white",
              margin: "0px  15px",
              padding: "5px 10px",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            By tapping save, I agree that the signature will be the electronic
            representation of my signature for all purposes when I (or my agent)
            use them on documents, including legally binding contracts - just
            the same as a pen-and-paper signature.
          </label>
        </div>
        {/* <div className="col-12 text-center" style={{ marginLeft: "11%" }}>
          <input
         
            id="uploadedImage"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={uploadedImageSelect}
          />
        </div> */}
      </div>

      <div
        className=" "
        style={{
          marginTop: 20,
          position: isMobile ? "relative" : "",
          bottom: isMobile ? "15px" : "",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className=" text-right text-center"
          style={{ marginTop: 10, display: "flex" }}
        >
          <button
            onClick={erase}
            className="btn"
            style={{
              marginRight: 20,
              backgroundColor: "white",
              color: "#2D3645",
              fontSize: 14,
              width: 92,
              height: 38,
            }}
          >
            Clear
          </button>
          <button
            onClick={save}
            className="btn"
            style={{
              marginRight: 8,
              marginLeft: 10,
              backgroundColor: "#2191ed",
              color: "white",
              fontSize: 14,
              width: 92,
              height: 38,
            }}
          >
            Save
          </button>
        </div>

        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="image-upload">
            <label for="uploadedImage" style={{ width: 40 }}>
              <img
                src={gallery_icon}
                width="30"
                height="25"
                style={{ marginTop: 17 }}
                //  position="relative"
              />
              <span
                style={{
                  marginLeft: "0px",
                  width: "10px",
                  position: "relative",
                  top: 8,
                }}
              >
                <input
                  id="uploadedImage"
                  type="file"
                  style={{
                    marginLeft: "200px",
                    width: "100px",
                    display: "none",
                  }}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={uploadedImageSelect}
                />
              </span>
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <i
              className={
                strokeStyle == colorStoke[0]
                  ? "fas fa-check-circle"
                  : "fas fa-circle"
              }
              style={{
                borderRadius: 25,
                color: "#F44336",
                fontSize: strokeStyle == colorStoke[0] ? 30 : 28,
                // marginLeft: 500,
                paddingLeft: 6,
                paddingTop: 6,
              }}
              onClick={() => {
                onColorChange(colorStoke[0]);
                setStrokeStyle(colorStoke[0]);
              }}
            />
            <i
              className={
                strokeStyle == colorStoke[1]
                  ? "fas fa-check-circle"
                  : "fas fa-circle"
              }
              style={{
                borderRadius: 25,
                color: "#2196F3",
                fontSize: strokeStyle == colorStoke[1] ? 30 : 28,
                // marginLeft: 500,
                paddingLeft: 6,
                paddingTop: 6,
              }}
              onClick={() => {
                onColorChange(colorStoke[1]);
                setStrokeStyle(colorStoke[1]);
              }}
            />
            <i
              className={
                strokeStyle == colorStoke[2]
                  ? "fas fa-check-circle"
                  : "fas fa-circle"
              }
              style={{
                borderRadius: 25,
                color: "#000000",
                fontSize: strokeStyle == colorStoke[2] ? 30 : 28,
                // marginLeft: 500,
                paddingLeft: 6,
                paddingTop: 6,
              }}
              onClick={() => {
                onColorChange(colorStoke[2]);
                setStrokeStyle(colorStoke[2]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canvas;
