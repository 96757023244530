import {endpoint} from '../helpers/constants'
import { handleResponse } from "../helpers/handleResponse";


export const contact   = async (name,email,message) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name,email,message }),
    };
  
    return fetch(`${endpoint}/auth/contact-us`, requestOptions).then(
        handleResponse
    );
  };
  