import React, { useEffect, useState } from "react";
import { documentService } from "../../services/documentService";
import Modal from "react-modal";
import "./dashboard.css";

import { documentHistoryModalStyle } from "../FromURL/fromURL";
import historyIcon from "../../images/history.svg";
import deleteIcon from "../../images/delete.svg";
import { DocumentHistory } from "../DocumentHistory/documentHistory";
import { DocumentStatus } from "../DocumentStatus/documentStatus";
import { useHistory } from "react-router-dom";

import { baseUrl } from "../../helpers/constants";
import Header from "../Header/header";
import Loader from "../Loader/loader";

const loaderModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 60,
    padding: 0,
    // height: 550,
    // backgroundColor: 'rgb(33, 25, 25/ 75%)'
    backgroundColor: "#E9E9E9",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

export const Dashboard = () => {
  const history = useHistory();

  const [documentList, setDocumentList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [creator, setCreator] = useState();

  const [showHistory, setShowHistory] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [documentHistory, setDocumentHistory] = useState(); // This is used for storing document with time stack
  const [documentInfo, setDocumentInfo] = useState();

  useEffect(() => {
    const temp = async () => {
      setIsLoading(true);
      const response = await documentService?.listDocumentsForDashboard();
      console.log(response, "res........................");
      setIsLoading(false);
      setDocumentList(response?.data?.documents);
    };
    try {
      temp();
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, []);

  const onRowClick = (id) => {
    console.log(documentList[id]);
    history.push(`/document/${documentList[id].uuid}`);
  };

  // // get creators
  // useEffect(() => {
  //     if (documentList && documentList.length > 0) {

  //         const creators = documentList.map((document) => {

  //             const assignedToUser = JSON.parse(document.assignedToUser);

  //             for (let j = 0; j < assignedToUser.length; ++j) {

  //                 if (assignedToUser[j].role === 'CREATOR') {
  //                     // console.log(assignedToUser[j]);
  //                     // setCreator(assignedToUser[j]);
  //                     return assignedToUser[j];
  //                 }
  //             }
  //         })

  //         // console.log(creators);
  //         setCreator(creators);

  //     }
  // }, [documentList])

  const getStatus = (index) => {
    const document = documentList[index];
    let message = "";
    let color = "";
    if (documentList[index].role === "CREATOR") {
      if (document.documentStatus === "SENT") {
        message = "Completed";
        color = "green";
      } else {
        message = "Remaining";
        color = "red";
      }
    } else {
      // Role is signer

      if (document.documentStatus === "SENT") {
        message = "Signed";
        color = "blue";
      } else {
        // message = 'Yet to sign'
        message = "Need to sign";
        color = "red";
      }
    }
    return (
      <div className={`text-sm font-semibold rounded-full text-${color}-600`}>
        {message}
      </div>
    );
  };

  const listDocumentHistory = async (id) => {
    try {
      setIsLoading(true);
      const response = await documentService.listDocument(id);
      setIsLoading(false);
      // console.log(response.response);
      setDocumentHistory(response.response);
      setShowHistory(true);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const listDocumentInfo = async (id) => {
    try {
      const response = await documentService.listDocumentInformation(id);
      setDocumentInfo(response);

      // console.log("Response is---->", response);
      setShowInfo(true);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDocument = async (id) => {
    try {
      setIsLoading(true);
      const response = await documentService.deleteDocument(id);
      setIsLoading(false);
      if (response.message === "Success") {
        window.location.reload();
      } else {
        console.log("Error deleting the document");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="min-h-screen" style={{ display: isLoading ? "none" : "" }}>
      <Header showLogout={true} />
      <div className="container" style={{ marginTop: "70px" }}>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              {documentList !== null && documentList?.length > 0 ? (
                <div className="shadow-md overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-12 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider"
                          style={{
                            backgroundColor: "white",
                            // "background-color": " rgba(233, 233, 233, 1)",
                            color: "black",
                            fontWeight: "bold",
                            borderBottom: "2px rgb(196,197,198)",
                          }}
                        >
                          Document Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider"
                          style={{
                            backgroundColor: "white",
                            // "background-color": " rgba(233, 233, 233, 1)",
                            color: "black",
                            fontWeight: "bold",
                            borderBottom: "2px rgb(196,197,198)",
                          }}
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-12 py-3 text-left text-sm font-medium text-gray-800 uppercase tracking-wider"
                          style={{
                            backgroundColor: "white",
                            // "background-color": " rgba(233, 233, 233, 1)",
                            color: "black",
                            fontWeight: "bold",
                            borderBottom: "2px rgb(196,197,198)",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {documentList &&
                        documentList.map((document, index) => {
                          return (
                            <tr key={document.id}>
                              <td className="px-12 py-4 whitespace-nowrap">
                                <div className="text-left">
                                  <div
                                    className="text-sm font-medium text-gray-900"
                                    style={{
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <a
                                      href={`${baseUrl}/document/${document.uuid}?redirect=true`}
                                      //  onClick={() => onRowClick(index)}
                                    >
                                      {document.originalname.substr(0, 50)}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {getStatus(index)}
                              </td>

                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    textAlign: "center",
                                    marginLeft: "75px",
                                  }}
                                >
                                  <div hidden={true} />
                                  <img
                                    alt="History"
                                    src={historyIcon}
                                    width="20"
                                    height="20"
                                    style={{
                                      marginLeft: -50,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      listDocumentHistory(document.id);
                                    }}
                                  />
                                  {/* {documentList[index].role === "CREATOR" ? (
                                    <img
                                      alt="Delete"
                                      src={deleteIcon}
                                      width="20"
                                      height="20"
                                      style={{
                                        marginLeft: 18,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        deleteDocument(document.id);
                                      }}
                                    />
                                  ) : null} */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className="font-weight-bold mt-48 text-gray-500"
                  style={{ textAlign: "center" }}
                >
                  No Document Found
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          isOpen={showHistory}
          style={documentHistoryModalStyle}
          onRequestClose={() => {
            setShowHistory(false);
          }}
        >
          {documentHistory && (
            // <div className="min-h-48 max-h-72">
            <DocumentHistory
              data={documentHistory}
              close={() => setShowHistory(false)}
            />
            // </div>
          )}
        </Modal>

        <Modal
          isOpen={showInfo}
          style={documentHistoryModalStyle}
          onRequestClose={() => {
            setShowInfo(false);
          }}
        >
          <DocumentStatus
            data={documentInfo}
            close={() => setShowInfo(false)}
          />
        </Modal>

        <Modal
          isOpen={isLoading}
          style={loaderModalStyle}
          onRequestClose={() => {
            setIsLoading(false);
          }}
        >
          <Loader />
        </Modal>
      </div>
    </div>
  );
};
