import React, { useEffect, useState } from "react";
import { login } from "../../services/loginService";
import { Error } from "../Error/error";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import Loader from "../Loader/loader";
import eye from "../../images/eye.svg";
import eyeSlash from "../../images/eye-slash.svg";
import loginImage from "../../images/login.png"; 
import newSignAppLogo from "../../images/sign vector_1.png"
import Locks from "../../images/locks.png"
import { baseUrl } from "../../helpers/constants";

const loginModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 60,
    padding: 0,
    // height: 550,
    // backgroundColor: 'rgb(33, 25, 25/ 75%)'
    backgroundColor: "#E9E9E9",
  },
  overlay: {
    background: "rgba(0,0,0,0.7)",
  },
};

export const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState();
  const [message, setMessage] = useState();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState();
  const [user, setUserError] = useState();
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("Hello");
    try {
      const token = localStorage.getItem("auth_token");
      if (token.length > 0) {
        history.push("/dashboard");
      }
    } catch (err) {
      localStorage.clear();
    }
  }, []);

  const handleClose = () => {
    setError(false);
  };

  // const openPasswordForgotPage = () => {
  //   window.open("https://signapp-web.netlify.app/reset-password");
  //   // window.open("http://localhost:3000/reset-password");
  // };

  const handleShowChange = (event) => {
    setShow(!show);
  };

  // const submit = async (e) => {
  async function submit(e) {
    
    e && e.preventDefault();
    console.log(password);
    try {
      if (email == "" || password == "") {
        if (email == "") {
          setEmailError(<span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>Email cannot be empty</span>)
        }
        if (password == "") {
          setPasswordError(<span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>Password cannot be empty</span>)
        }

      }
      else {
        setIsLoading(true);
        const response = await login(email, password);
        setIsLoading(false);
        console.log(response);
        if (response) {
          console.log(response)
          if (!response.user.isActive) {
            setMessage("Plese verify your account");
            setError(true);
          } else {
            window.localStorage.setItem("auth_token", response.token);
            window.localStorage.setItem("email", email);
            history.push("/dashboard");
          }
        }
      }

    } catch (e) {
      setIsLoading(false);
      console.log(e);
      if (e.message) {
        setUserError(<span style={{ color: "red", fontSize: "15px", paddingLeft: "3px" }}>No such email exists</span>);
      }
      else{
        setUserError()
      }
    }
    //change in up function
    // try {
    //   setIsLoading(true);
    //   const response = await login(email, password);
    //   setIsLoading(false);
    //   console.log(response)
    //   if (!response.user.isActive) {
    //     setMessage("Plese verify your account");
    //     setError(true);

    //   } else {
    //     window.localStorage.setItem("auth_token", response.token);
    //     window.localStorage.setItem("email", email);
    //     history.push("/dashboard");
    //   }
    // } catch (e) {
    //   setIsLoading(false);
    //   setMessage(e);
    //   setError(true);
    // }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center sm:py-12">
      <div className="p-10 xs:p-0 mx-auto xl:w-full xl:max-w-xl">
        <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200 p-8">
          <div className="plr-24 space-y-8">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={newSignAppLogo}
                alt="Workflow"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Sign in
              </h2>
            </div>

            <form onSubmit={submit}>
              {/* <input type="hidden" name="remember" defaultValue="true" /> */}
              <div className="mt-8 space-y-6">

                <div >
                  <div className="mb-1">
                    {
                      user
                    }
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                  </label>
                    <input
                      // autoFocus={true}
                      style={{ paddingRight: "39px !important" }}
                      id="email-address"
                      name="email"
                      // type="email"
                      className="appearance-none rounded-none relative block w-full px-3 py-2 pr-5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                      onChange={(e) => {
                        setEmail(e.target.value.toLowerCase())
                        if (e.target.value.trim()== "") {
                          setEmailError(<span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>Email cannot be empty</span>)
                        }
                        else if (!e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                          setEmailError(<span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>Please enter valid email address </span>)
                        }
                        else {
                          setEmailError();
                     
                        }
                        setUserError();
                      }}
                    />
                    {
                      emailError
                    }
                  </div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <label htmlFor="password" className="sr-only">
                      Password
                  </label>
                    <input
                      id="password"
                      name="password"
                      style={{ paddingRight: "39px !important" }}
                      type={show ? "text" : "password"}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 pr-5 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value.trim())
                        if (e.target.value.trim() == "") {
                          setPasswordError(<span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>Password cannot be empty</span>)
                        }
                        else if (e.target.value.length < 6) {
                          setPasswordError(<span style={{ color: "red", fontSize: "12px", paddingLeft: "3px" }}>Password must be minimum 6 characters</span>)
                        }
                        else {
                          setPasswordError();
                        }
                        setUserError();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          submit();
                        }
                      }}
                    />
                    {
                      passwordError
                    }
                    <img
                      src={show ? eyeSlash : eye}
                      width={20}
                      height={20}
                      alt="show password"
                      style={{ position: "absolute", top: 10, right: 15 }}
                      onClick={handleShowChange}
                    />
                    {/*</div>*/}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <a
                      href={`${baseUrl}/reset-password`}
                      target="_blank"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      rel="noreferrer"
                      style={{ color: "#378cf2" }}
                    // onClick={openPasswordForgotPage}
                    >
                      Forgot your password?
                  </a>
                  </div>
                </div>
                <div>
                  <button
                    style={{ backgroundColor: "#378cf2" }}
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  // onClick={submit}
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      {/* <svg
                      className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clipRule="evenodd"
                      />
                    </svg> */}
                      <img src={Locks} height="18px" width="18px"></img>
                    </span>
                  Sign In
                </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
      <Modal
        autoFocus={false}
        isOpen={isLoading}
        style={loginModalStyle}
        onRequestClose={() => {
          setIsLoading(false);
        }}
      >
        <Loader />
      </Modal>
      <Error
        show={error}
        message={message}
        duration={4000}
        position={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      />
    </div>
  );
};
