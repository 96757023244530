import React from "react";

const Footer = () => {
    return (
        <>
            <footer
                style={{
                    background: "white",
                    color: "black",
                    padding: "25px",
                    // position: "fixed",
                    left: "0",
                    bottom: "0",
                    width: "100%",
                    borderTop: "1px solid rgb(181 181 181)",
                }}
            >
                <p>
                    Powered by <b>Sign Documents: SignPe</b>
                </p>
                <div style={{ display: "flex" }}>
                    <a href="https://support.signpe.com/">
                        <p style={{ paddingRight: "10px" }}> Contact Us</p>
                    </a>
                    <a href="https://signpe.com/terms-of-use/">
                        <p style={{ paddingRight: "10px" }}> Terms of Use</p>
                    </a>
                    <a href=" https://signpe.com/privacy-policy/">
                        <p>Privacy Policy </p>
                    </a>
                </div>


                <p>Copyright &copy; Sign Documents: SignPe. All rights reserved.</p>
            </footer>
        </>
    );
};

export default Footer;
